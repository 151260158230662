import { countries } from '../constants/countries';
import { ENDPOINTS } from './endpoints';
import {
  AnswerObjectType,
  CandidateProfile,
  CandidateSkillPayload,
  ClientJobPayloadForback,
  ClientJobPostPayload,
  ClientOrganizationPayloadData,
  ClientPersonalData,
  ClientProfilePayloadData,
  ClientSignUpPayload,
  ClinetInvitationPayload,
  ClinetUpdatedInvitationPayload,
  CompleteSignupPayload,
  ForgotPasswordPayload,
  InterviewFeedbackType,
  InterviewInviteForCandidatesPayload,
  LoginPayload,
  PodEntryInitiate,
  ResendOTPPayload,
  ResetPasswordPayload,
  SignUpPayload,
  VerifyOTPPayload,
  changePasswordPayload,
  createInterviewRoundPayload,
  existingJobPayload,
  hiringStatus,
  onboardingProfilePayloadData,
  payloadForPreSignedURL,
  shortListedmailIdsList,
  uploadFileData,
  validatePodOTP,
} from './types';

export const REST_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const RESPONSE_CODES = {
  OTP_VERIFICATION: 'OTP_VERIFICATION',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  OTP_ALREADY_VERIFIED: 'OTP_ALREADY_VERIFIED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  OTP_RETRY_LIMIT_EXCEEDED: 'OTP_RETRY_LIMIT_EXCEEDED',
  OTP_RESEND_VERIFICATION: 'OTP_RESEND_VERIFICATION',
  USER_NOT_PRESENT: 'USER_NOT_PRESENT',
  OTP_ALREADY_USED: 'OTP_ALREADY_USED',
  OTP_UNVERIFIED: 'OTP_UNVERIFIED',
  REQUEST_MALFORMED: 'REQUEST_MALFORMED',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  OTP_VERIFIED: 'OTP_VERIFIED',
  RETRY_LIMIT_EXCEEDED: 'RETRY_LIMIT_EXCEEDED',
  INVALID_OTP: 'INVALID_OTP',
  EXPIRED_OTP: 'EXPIRED_OTP',
  USER_BLOCKED_FOR_OTP: 'USER_BLOCKED_FOR_OTP',
  EMAIL_DOES_NOT_EXIST: 'EMAIL_DOES_NOT_EXIST',
  UNAUTHORIZED: 'UNAUTHORIZED',
  LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL',
  BAD_REQUEST: 'BAD_REQUEST',
  SIGNUP_COMPLETE: 'SIGNUP_COMPLETE',
  NOT_FOUND: 'NOT_FOUND',
  TEST_IN_PUBLISHED_STATUS: 'TEST_IN_PUBLISHED_STATUS',
  CLIENT_ACCOUNT_SELECTION: 'CLIENT_ACCOUNT_SELECTION',
  RESOLVE_DUPLICATE_ACCOUNT: 'RESOLVE_DUPLICATE_ACCOUNT',
  DUPLICATE_ACCOUNT: 'DUPLICATE_ACCOUNT',
  UNREGISTERED_USER: 'UNREGISTERED_USER',
  IDENTITY_UN_VERIFIED: 'IDENTITY_UN_VERIFIED',
  USER_IS_NOT_CANDIDATE: 'USER_IS_NOT_CANDIDATE',
  SCANNED_WRONG_POD: 'SCANNED_WRONG_POD',
  SLOT_EXPIRED: 'SLOT_EXPIRED',
  MULTIPLE_INTERVIEWS_FOUND: 'MULTIPLE_INTERVIEWS_FOUND',
  EARLY_FOR_INTERVIEW: 'EARLY_FOR_INTERVIEW',
};

const categoryData = {
  categories: [
    'Executive Leadership or C-Suite',
    'Development and Programming',
    'Testing and Quality Assurance',
    'Systems and Network Administration',
    'Database Management',
    'Cybersecurity',
    'Business Analysis and Project Management',
    'IT Consulting',
    'Artificial Intelligence (AI) and Machine Learning (ML)',
    'Web and Graphic Design',
    'Technical Support and Helpdesk',
    'Enterprise Resource Planning (ERP)',
    'Software Architecture',
    'Data Science and Big Data',
    'Education and Training',
    'Sales',
    'Marketing',
    'Communication',
  ],
};

const jobCategoryDetails = {
  'Executive Leadership or C-Suite': [
    'COO (Chief Operating Officer):Responsible for overseeing the day-to-day operations of the company and ensuring that business processes are efficient and effective.',
    "CFO (Chief Financial Officer):Responsible for financial planning, reporting, and management. Manages the company's financial risks and opportunities.",
    "CTO (Chief Technology Officer):Responsible for the company's technology strategy, innovation, and ensuring that technological resources are aligned with the organization's goals.",
    "CMO (Chief Marketing Officer):Responsible for developing and implementing marketing strategies to promote the company's products or services.",
    'CHRO (Chief Human Resources Officer):Responsible for human resources management, including recruitment, training, and employee relations.',
    'CIO (Chief Information Officer):Responsible for the overall technology strategy and information technology management within the organization.',
    "CSO (Chief Strategy Officer):Responsible for developing and implementing the company's overall strategic initiatives.",
    'CDO (Chief Diversity Officer):Responsible for promoting diversity and inclusion within the organization.',
  ],
  'Development and Programming': [
    'Software Developer:Designs, codes, tests, and maintains software applications.Collaborates with other team members to analyze requirements and implement solutions.',
    'Web Developer:Specializes in the development of websites and web applications.Proficient in web technologies such as HTML, CSS, JavaScript, and various frameworks.',
    'Mobile App Developer:Designs and develops applications for mobile devices (iOS, Android).Familiar with mobile development frameworks and platforms.',
    'Full-stack Developer:Works on both the front-end (user interface) and back-end (server-side) of applications.Has a broad range of skills covering various technologies.',
    'Front-end Developer:Focuses on the user interface and user experience of applications.Proficient in HTML, CSS, JavaScript, and front-end frameworks.',
    'Back-end Developer:Focuses on server-side development and database interactions.Works with server-side languages and frameworks.',
    'DevOps Engineer:Integrates development and operations to improve collaboration and productivity.Automates processes related to building, testing, and deploying applications.',
    'Cloud Engineer:Specializes in designing, implementing, and managing cloud infrastructure.Works with platforms such as AWS, Azure, or Google Cloud.',
    'Embedded Systems Developer:Designs and develops software for embedded systems (e.g., IoT devices).Understands hardware-software integration.',
    'Game Developer:Designs and develops video games for various platforms.Proficient in game development frameworks and engines.',
    'Data Engineer:Focuses on the development and management of data architecture.Designs and builds data pipelines for analytics and machine learning.',
    'Blockchain Developer:Specializes in developing decentralized applications (DApps) using blockchain technology.Familiar with smart contracts and various blockchain platforms',
    'Machine Learning Engineer:Specializes in designing and implementing machine learning models.Works on projects related to artificial intelligence and data science.',
  ],
  'Testing and Quality Assurance': [
    'Software Tester/QA Tester:Responsible for testing software applications to identify defects and ensure they meet specified requirements. Executes test cases, reports issues, and collaborates with developers to resolve defects.',
    'Test Engineer:Designs and develops test plans, test cases, and test scripts. Performs automated and manual testing to validate software functionality.',
    'Automation Engineer:Specializes in designing, developing, and maintaining automated test scripts. Implements and executes automated testing frameworks to increase efficiency.',
    'Quality Assurance (QA) Analyst:Analyzes system requirements and creates test plans and test cases. Conducts various types of testing, such as functional, regression, and performance testing.',
    'Performance Tester:Focuses on evaluating the performance and scalability of software applications. Conducts load testing, stress testing, and performance optimization.',
    'Security Tester (or Penetration Tester):Identifies and assesses security vulnerabilities in software applications. Conducts penetration testing to ensure the security of systems.',
    'User Acceptance Testing (UAT) Tester:Collaborates with end-users to validate that the software meets their requirements. Ensures the software is ready for production use.',
    'Test Manager/Test Lead:Manages the testing team and oversees the planning and execution of testing activities. Coordinates with project managers and stakeholders to ensure testing aligns with project goals.',
    'Quality Assurance Manager/Director:Provides leadership and strategic direction for the overall quality assurance process. Ensures that QA processes are aligned with organizational goals and industry best practices.',
    'Test Automation Architect:Designs and implements the overall architecture of automated testing frameworks. Provides guidance on best practices for test automation.',
    'Accessibility Tester:Focuses on evaluating and ensuring that software applications are accessible to users with disabilities. Conducts testing based on accessibility standards and guidelines.',
    'Configuration Manager:Manages and controls the configuration of software and test environments. Ensures consistency and integrity across different stages of development and testing.',
  ],
  'Systems and Network Administration': [
    'System Administrator:Responsible for the day-to-day operations and maintenance of servers, systems, and networks. Installation, configuration, and troubleshooting of hardware and software. User account management and access control.',
    "Network Engineer:Design, implement, and manage an organization's networks. Configure and maintain network devices, such as routers and switches. Troubleshoot network issues and optimize network performance.",
    "System Architect:Develop and implement system architecture and design. Define the overall structure and layout of systems and networks. Ensure that the architecture supports the organization's business objectives.",
    'DevOps Engineer:Focus on collaboration between development and operations teams. Automation of software development and infrastructure processes. Deployment and continuous integration to enhance development efficiency.',
    'Cloud Administrator:Manage and maintain cloud-based infrastructure and services. Work with cloud platforms such as AWS, Azure, or Google Cloud. Monitor and optimize cloud resources.',
    'IT Security Administrator:Implement and manage security measures to protect systems and networks. Monitor and respond to security incidents. Ensure compliance with security policies and regulations.',
    'Virtualization Administrator:Implement and manage virtualization technologies (e.g., VMware, Hyper-V). Create and maintain virtual machines. Monitor and optimize virtualized environments.',
    'Backup and Recovery Specialist:Develop and implement backup and recovery strategies. Ensure data integrity and availability in case of data loss or system failure.',
    'IT Support Specialist:Provide technical support to end-users. Troubleshoot hardware and software issues. Assist with IT-related inquiries and problems.',
    'Wireless Network Engineer:Design, implement, and manage wireless networks. Optimize wireless network performance and troubleshoot issues.',
    'System Operations Center (SOC) Analyst:Monitor and analyze system and network activities for security incidents. Respond to and mitigate security threats. Work in collaboration with cybersecurity teams.',
  ],
  'Database Management': [
    'Database Administrator (DBA):Responsible for the overall management and maintenance of databases. Installation, configuration, and upgrading of database software. Monitoring database performance and ensuring data integrity and security.',
    'Database Developer:Focuses on designing, coding, and implementing database systems. Develops and optimizes SQL queries, stored procedures, and triggers. Collaborates with application developers to integrate database functionality.',
    'Data Architect:Designs and creates the structure of databases to meet organizational needs. Defines data standards and models for efficient data management. Collaborates with developers and business analysts to understand data requirements.',
    'Data Analyst:Analyzes and interprets complex data sets to provide insights. Designs and develops data visualizations and reports. Works closely with business stakeholders to understand data needs.',
    'Data Warehouse Specialist:Focuses on the design and maintenance of data warehouses. Extracts, transforms, and loads (ETL) data from various sources into data warehouses. Optimizes data warehouse performance and scalability.',
    'Database Security Specialist:Implements and maintains security measures to protect databases from unauthorized access. Monitors and audits database activities for compliance. Collaborates with IT security teams to implement best practices.',
    'Database Performance Tuning Expert:Optimizes database performance through query tuning and index optimization. Monitors and analyzes system resource utilization. Recommends and implements improvements to enhance database efficiency.',
    'NoSQL Database Administrator:Specializes in the administration of NoSQL databases (e.g., MongoDB, Cassandra). Manages distributed and non-relational databases. Implements and maintains high-availability configurations.',
    'Database Cloud Administrator:Manages and administers databases in cloud environments (e.g., AWS, Azure, Google Cloud). Implements and maintains database-as-a-service solutions. Ensures data security and compliance in the cloud.',
    'Database Consultant:Provides expertise and guidance to organizations on database-related projects. Conducts assessments, designs database solutions, and offers recommendations. Assists with database migrations, upgrades, and performance improvements.',
  ],
  Cybersecurity: [
    "Information Security Analyst:Monitor an organization's computer systems for security breaches. Implement security measures to protect computer systems and data. Conduct security assessments and audits.",
    'Network Security Engineer:Design, implement, and manage security measures for networks. Monitor network traffic for suspicious activity. Implement firewalls, VPNs, and other network security technologies.',
    'Security Consultant:Provide expert advice on cybersecurity strategy and solutions. Conduct risk assessments and recommend security improvements. Assist organizations in developing and implementing security policies.',
    'Ethical Hacker (Penetration Tester):Conduct authorized simulated cyber attacks to identify vulnerabilities. Test and assess the security of systems and applications. Provide recommendations to improve security posture.',
    'Incident Responder:Investigate and respond to cybersecurity incidents and breaches. Develop and implement incident response plans. Mitigate the impact of security incidents.',
    'Security Architect:Design and implement secure systems and architectures. Develop and enforce security policies. Ensure that security is integrated into the overall system design.',
    'Security Operations Center (SOC) Analyst:Monitor security alerts and incidents. Analyze and respond to security events in real-time. Work with incident responders to address security issues.',
    'Security Administrator:Manage and configure security tools and technologies. Implement and maintain access controls and authentication systems. Ensure compliance with security policies.',
    'Cryptographer:Develop and implement cryptographic algorithms. Design secure encryption and decryption processes. Research and analyze cryptographic techniques.',
    'Security Compliance Analyst:Ensure compliance with industry regulations and standards. Conduct audits and assessments to verify adherence to security policies. Provide guidance on compliance requirements.',
    'Security Awareness and Training Specialist:Develop and deliver cybersecurity training programs. Raise awareness about security best practices among employees. Monitor and assess the effectiveness of training initiatives.',
    'Identity and Access Management (IAM) Specialist:Manage user access to systems and applications. Implement and maintain identity and access controls. Ensure proper authentication and authorization processes.',
  ],
  'Business Analysis and Project Management': [
    'Business Analyst:Analyzing and documenting business processes. Eliciting and documenting requirements from stakeholders. Creating use cases, user stories, and functional specifications. Facilitating communication between business stakeholders and development teams.',
    'Systems Analyst:Focusing on the technical aspects of systems and applications. Analyzing and designing information systems to meet business needs. Bridging the gap between business requirements and technical solutions.',
    'Requirements Analyst:Specializing in gathering and documenting requirements for projects. Collaborating with stakeholders to prioritize and define project requirements.',
    'Product Owner:Representing the customer and defining product features. Prioritizing and managing the product backlog. Collaborating with the development team to ensure delivery of customer value.',
    'Project Manager:Planning and overseeing the execution of projects. Managing project timelines, budgets, and resources. Ensuring that project goals align with business objectives.',
    'Program Manager:Overseeing multiple related projects within a program. Coordinating interdependencies between projects. Managing overall program goals and deliverables.',
    'Scrum Master:Facilitating Agile/Scrum ceremonies and processes. Removing impediments and promoting team collaboration. Ensuring that Agile principles are followed.',
    'Project Coordinator:Assisting project managers in administrative tasks. Coordinating project activities and resources. Maintaining project documentation.',
    'Portfolio Manager:Managing a portfolio of projects to achieve strategic objectives. Aligning projects with organizational goals. Prioritizing projects based on resource constraints and business priorities.',
    'Change Management Specialist:Focusing on managing the human side of change within an organization. Developing change management plans to support project implementations. Addressing resistance and fostering a positive change environment.',
  ],
  'IT Consulting': [
    'Business Analyst:Analyzing and documenting business processes. Eliciting and documenting requirements from stakeholders. Creating use cases, user stories, and functional specifications. Facilitating communication between business stakeholders and development teams.',
    'Systems Analyst:Focusing on the technical aspects of systems and applications. Analyzing and designing information systems to meet business needs. Bridging the gap between business requirements and technical solutions.',
    'Requirements Analyst:Specializing in gathering and documenting requirements for projects. Collaborating with stakeholders to prioritize and define project requirements.',
    'Product Owner:Representing the customer and defining product features. Prioritizing and managing the product backlog. Collaborating with the development team to ensure delivery of customer value.',
    'Project Manager:Planning and overseeing the execution of projects. Managing project timelines, budgets, and resources. Ensuring that project goals align with business objectives.',
    'Program Manager:Overseeing multiple related projects within a program. Coordinating interdependencies between projects. Managing overall program goals and deliverables.',
    'Scrum Master:Facilitating Agile/Scrum ceremonies and processes. Removing impediments and promoting team collaboration. Ensuring that Agile principles are followed.',
    'Project Coordinator:Assisting project managers in administrative tasks. Coordinating project activities and resources. Maintaining project documentation.',
    'Portfolio Manager:Managing a portfolio of projects to achieve strategic objectives. Aligning projects with organizational goals. Prioritizing projects based on resource constraints and business priorities.',
    'Change Management Specialist:Focusing on managing the human side of change within an organization. Developing change management plans to support project implementations. Addressing resistance and fostering a positive change environment.',
  ],
  'Artificial Intelligence (AI) and Machine Learning (ML)': [
    'Data Scientist:Analyzes and interprets complex datasets to inform business decision-making. Develops and trains machine learning models. Identifies patterns, trends, and insights from data.',
    'Machine Learning Engineer:Designs and builds machine learning models and systems. Works on feature engineering, model selection, and hyperparameter tuning. Collaborates with data scientists and software engineers.',
    'AI Research Scientist:Conducts research to advance the field of artificial intelligence. Develops new algorithms and models. Publishes research papers and stays current with the latest advancements.',
    'Natural Language Processing (NLP) Engineer:Focuses on understanding and processing human language. Works on tasks such as text analysis, sentiment analysis, and language translation. Develops NLP algorithms and models.',
    'Computer Vision Engineer:Specializes in creating algorithms for interpreting and understanding visual information. Works on image and video analysis, object recognition, and image processing.',
    'Data Engineer:Designs, develops, and maintains the infrastructure for data generation, transformation, and storage. Ensures data is accessible and ready for analysis by data scientists.',
    'Big Data Engineer:Manages and processes large-scale datasets. Works with distributed computing frameworks like Apache Hadoop and Apache Spark.',
    'AI Product Manager:Drives the development of AI-powered products. Collaborates with cross-functional teams to define product strategy and features. Understands market needs and customer requirements.',
    'AI Ethics Specialist:Focuses on the ethical implications of AI systems. Ensures fairness, transparency, and accountability in AI algorithms and applications.',
    'AI Consultant:Provides expertise to organizations adopting AI technologies. Assists with strategy development, implementation, and optimization of AI solutions.',
    'AI Software Developer:Implements AI algorithms into software applications. Works on integrating AI features into existing systems.',
    'Robotics Engineer:Designs, builds, and maintains robotic systems. Combines AI and robotics for automation and intelligent control.',
    'AI Trainer/Annotator:Prepares and labels training data for machine learning models. Assists in creating high-quality datasets for model training.',
    'AI Infrastructure Engineer:Manages the infrastructure required to deploy and scale AI models. Works on optimization, deployment, and monitoring of AI systems.',
    'AI Governance Specialist:Ensures compliance with regulations and ethical guidelines in AI development. Establishes policies for responsible AI use.',
  ],
  'Web and Graphic Design': [
    'UI/UX Designer (User Interface/User Experience Designer):Focuses on designing the user interface and user experience of websites and applications. Creates wireframes, prototypes, and visual designs to enhance user interaction.',
    'Graphic Designer:Specializes in creating visual content for both online and offline media. Designs logos, marketing materials, brochures, and other graphic elements.',
    'Web Designer:Focuses specifically on the visual design of websites. Works on layout, color schemes, and overall aesthetics to create an engaging user experience.',
    'Interaction Designer:Designs interactive elements within digital interfaces. Focuses on creating seamless and intuitive user interactions.',
    'Motion Graphics Designer:Specializes in creating animated visual content. Works with video, animation, and other dynamic media.',
    'Brand Identity Designer:Focuses on creating and maintaining the visual identity of a brand. Designs logos, color schemes, and other brand elements.',
    'Art Director:Provides creative direction and oversees the visual aspects of design projects. Coordinates and manages design teams to ensure a cohesive visual style.',
    'Web Developer with Design Skills:Combines web development skills with design expertise. Implements the visual aspects of websites while also handling front-end development.',
    'Illustrator:Creates illustrations and visual elements to enhance design projects. Often works with both traditional and digital illustration tools.',
    'Email Designer:Specializes in designing visually appealing email campaigns. Ensures that email designs are responsive and compatible with various email clients.',
    'Packaging Designer:Designs the visual elements for product packaging. Considers both aesthetic and functional aspects of packaging design.',
    'User Researcher (Design):Conducts research to understand user needs and preferences. Provides valuable insights to inform design decisions.',
    'Content Designer:Focuses on creating visually appealing and engaging content for online platforms. Collaborates with content creators and marketing teams.',
  ],
  'Technical Support and Helpdesk': [
    'Technical Support Specialist:Provides technical assistance and support to end-users. Troubleshoots hardware and software issues. Assists with the installation and configuration of software and hardware.',
    'IT Support Technician:Offers technical support to internal staff or external customers. Diagnoses and resolves technical problems. Installs and configures computer systems and applications.',
    'Helpdesk Analyst:Serves as the initial point of contact for technical issues. Logs, tracks, and prioritizes support requests. Provides remote assistance and troubleshooting.',
    'Desktop Support Engineer:Focuses on supporting end-users with desktop computer issues. Installs, maintains, and upgrades hardware and software. Troubleshoots and resolves desktop-related problems.',
    'Service Desk Technician:Manages and resolves incidents reported by end-users. Provides guidance on IT policies and procedures. Ensures timely resolution of technical issues.',
    'User Support Specialist:Offers technical support for a specific group of end-users. Provides training and assistance on software and systems. Troubleshoots and resolves user-related issues.',
    'IT Helpdesk Manager:Manages and oversees the operations of the IT helpdesk. Develops and implements support policies and procedures. Ensures the helpdesk team meets service level agreements.',
    'IT Support Coordinator:Coordinates and schedules technical support activities. Acts as a liaison between end-users and the IT support team. Ensures timely and effective support services.',
    'Remote Support Specialist:Provides technical assistance to end-users remotely. Troubleshoots issues through phone, chat, or remote desktop tools. Resolves technical problems without being physically present.',
    'Technical Customer Support Representative:Assists customers with technical inquiries and issues. Provides guidance on product usage and problem resolution. Collaborates with other teams to resolve complex technical problems.',
  ],
  'Enterprise Resource Planning (ERP)': [
    'ERP Consultant:Provides expertise in ERP systems and advises organizations on the selection, implementation, and optimization of ERP solutions. Analyzes business processes and requirements to recommend suitable ERP solutions. Assists in the customization and configuration of the ERP system to meet specific organizational needs.',
    'ERP Project Manager:Leads the planning, execution, and monitoring of ERP implementation projects. Manages project timelines, resources, and budgets. Coordinates communication between different teams involved in the ERP project.',
    'Functional Consultant:Focuses on the functional aspects of the ERP system, understanding business processes and requirements. Collaborates with users to gather and document functional requirements. Configures the ERP system to align with business processes.',
    'Technical Consultant:Deals with the technical aspects of ERP systems, including customization, integration, and data migration. Develops and maintains technical documentation. Collaborates with developers and system administrators to ensure smooth technical operations.',
    'ERP Developer:Designs, develops, and customizes ERP software to meet specific organizational needs. Codes and tests ERP modules and functionalities. Collaborates with functional consultants to align technical solutions with business requirements.',
    'Data Analyst:Manages and analyzes data within the ERP system. Creates reports and dashboards to provide insights into business performance. Ensures data accuracy and integrity within the ERP database.',
    'System Administrator:Manages the overall health and performance of the ERP system. Handles user access, security, and system configurations. Performs routine maintenance and troubleshooting tasks.',
    'Trainer:Provides training sessions to end-users and key stakeholders on how to use the ERP system. Develops training materials and documentation. Supports users during the initial implementation phase and ongoing system updates.',
    'Support Analyst:Offers ongoing support to end-users, addressing issues and resolving problems related to the ERP system. Communicates with software vendors for issue resolution and updates. Provides assistance during system upgrades and enhancements.',
  ],
  'Software Architecture': [
    'Software Architect:Responsible for designing the overall structure of the software system. Defines the architecture, including high-level components, modules, and their interactions. Makes decisions on technology stack, frameworks, and design patterns.',
    'Solution Architect:Focuses on designing solutions to specific problems or requirements. Collaborates with stakeholders to understand their needs and constraints. Designs solutions that align with the overall software architecture.',
    "Enterprise Architect:Takes a holistic view of the entire organization's IT infrastructure. Aligns software architecture with the overall business strategy. Ensures that different software systems within the organization work together cohesively.",
    'Technical Architect:Focuses on the technical aspects of the software solution. Works closely with development teams to ensure the implementation follows architectural guidelines. Often responsible for ensuring scalability, performance, and security.',
    'Integration Architect:Specializes in designing the integration of different software systems. Ensures that disparate systems can communicate and work together seamlessly. Addresses issues related to data flow, APIs, and middleware.',
    'Security Architect:Focuses on designing and implementing security measures within the software architecture. Identifies and mitigates potential security risks and vulnerabilities. Ensures compliance with security standards and best practices.',
    'Data Architect:Specializes in designing the data structures and databases within the software system. Defines data models, database schemas, and data flow. Ensures data integrity, availability, and performance.',
    'Infrastructure Architect:Focuses on the underlying IT infrastructure that supports the software system. Designs the hardware, networking, and server architecture. Ensures scalability, reliability, and performance of the infrastructure.',
    'UI/UX Architect:Focuses on designing the user interface and user experience of the software system. Defines the layout, navigation, and visual elements of the user interface. Ensures a user-friendly and intuitive design.',
    'Cloud Architect:Specializes in designing software solutions that leverage cloud computing services. Defines the architecture for deploying and managing applications in the cloud. Ensures scalability, cost-effectiveness, and reliability in a cloud environment.',
  ],
  'Data Science and Big Data': [
    'Data Scientist:Analyzes and interprets complex datasets to inform business decision-making. Applies statistical models and machine learning algorithms to extract insights. Develops and implements predictive models.',
    'Big Data Engineer:Designs, builds, and manages large-scale data processing systems. Works with technologies like Hadoop, Spark, and other distributed computing frameworks. Ensures the scalability, reliability, and efficiency of big data solutions.',
    'Data Engineer:Builds, tests, and maintains data architectures (e.g., data pipelines, ETL processes). Integrates, consolidates, and processes data from various sources. Collaborates with data scientists and analysts to ensure data availability.',
    'Machine Learning Engineer:Focuses on implementing machine learning algorithms in production environments. Optimizes and deploys models for real-world applications. Collaborates with data scientists and software engineers.',
    'Data Analyst:Examines, cleans, and interprets data to support decision-making. Creates visualizations and reports to communicate insights. Conducts statistical analysis and data exploration.',
    'Business Intelligence (BI) Developer:Designs and develops business intelligence solutions. Creates dashboards, reports, and data visualizations. Integrates BI tools with existing systems.',
    'Data Architect:Designs and structures data systems and databases. Ensures data integration, integrity, and security. Collaborates with other IT professionals to implement data solutions.',
    'Quantitative Analyst:Applies mathematical and statistical techniques to financial and business problems. Develops models for risk assessment and financial forecasting.',
    'Data Science Manager/Director:Leads and manages a team of data scientists and analysts. Defines the data strategy and ensures alignment with business goals. Collaborates with other departments to drive data-driven decision-making.',
    'Data Governance Specialist:Establishes and enforces data governance policies and standards. Ensures data quality, security, and compliance. Collaborates with stakeholders to define data governance processes.',
    'Data Privacy Officer/Analyst:Focuses on ensuring compliance with data privacy regulations. Develops and implements privacy policies and procedures.',
    'IoT (Internet of Things) Data Scientist/Engineer:Analyzes data generated by IoT devices. Develops algorithms to extract meaningful insights from IoT data streams.',
  ],
  'Education and Training': [
    'IT Trainer:Responsible for conducting training sessions on specific IT topics or technologies. Develops training materials, curriculum, and hands-on exercises. Assesses the learning needs of participants and adjusts training methods accordingly.',
    'Training Coordinator:Manages the logistics and coordination of IT training programs. Schedules training sessions and ensures that necessary resources are available. Collaborates with trainers, participants, and other stakeholders.',
    'Curriculum Developer:Designs and develops curriculum for IT training programs. Ensures that training materials align with industry standards and best practices. Updates curriculum to reflect changes in technology and educational trends.',
    'Instructional Designer:Creates instructional materials, including presentations, videos, and e-learning modules. Incorporates adult learning principles and instructional design methodologies. Collaborates with subject matter experts to ensure accuracy and relevance.',
    'Learning and Development Specialist:Focuses on the overall development of employees through training and educational programs. Identifies learning needs, designs training initiatives, and evaluates their effectiveness. Collaborates with HR and management to address skill gaps and improve performance.',
    'Technical Writer:Creates documentation, manuals, and guides for IT products and services. Develops training materials to help users understand and use technology effectively. Ensures that documentation is clear, concise, and user-friendly.',
    'Online Learning Facilitator:Manages and facilitates online learning platforms and courses. Supports learners in virtual environments and fosters online collaboration. Monitors participant progress and provides assistance as needed.',
    'Technology Trainer for Educators:Provides training and support for educators integrating technology into the classroom. Assists teachers in using educational software, tools, and online resources. Conducts professional development sessions for educational staff.',
    'Certification Instructor:Prepares individuals for IT certifications by delivering training programs. Covers the content and skills required for specific industry certifications. Helps participants prepare for and succeed in certification exams.',
    'Education Consultant:Advises educational institutions or organizations on IT training strategies. Assesses learning needs and recommends appropriate training solutions. Stays informed about industry trends and best practices in IT education.',
    'Teacher:Classroom Teacher, Subject-Specific Teacher (e.g., Math Teacher, English Teacher), Special Education Teacher, Preschool Teacher, Higher Education Professor',
    'Educational Administrator:School Principal, School Superintendent, School Administrator, College or University Dean',
    'Education Consultant:Curriculum Development Specialist, Instructional Design Consultant, Education Technology Consultant',
    'School Counselor:Academic Counselor, Career Counselor, Guidance Counselor',
    'Librarian:School Librarian, Academic Librarian, Research Librarian',
    'Education Researcher:Conducts research to improve educational practices and policies.',
    'Educational Psychologist:Focuses on understanding and addressing psychological aspects of learning.',
    'Training and Development Specialist:Designs and conducts training programs for employees in various industries.',
    'Adult Education Instructor:Teaches adult learners in areas such as literacy, language, or vocational skills.',
    'Online Education Facilitator:Manages and facilitates online courses and programs.',
    'Tutor:Provides one-on-one or small group instruction to students.',
    'Career and Technical Education (CTE) Instructor:Teaches specific skills related to a particular career or trade.',
    'Education Technology Specialist:Integrates technology into the learning environment and provides technical support.',
    'Language Instructor: Teaches foreign languages or English as a second language (ESL).',
    'Physical Education Instructor:Teaches physical fitness, sports, and health education.',
    'Special Education Coordinator:Manages and coordinates special education programs and services.',
    'Educational Outreach Coordinator:Develops and implements outreach programs to engage the community.',
    'Admissions Officer:Works in educational institutions to manage admissions processes.',
    'Museum Educator:Creates educational programs and activities within a museum setting.',
    'Corporate Trainer:Designs and delivers training programs within a corporate setting.',
  ],
  Sales: [
    'Sales Representative:Acts as the main point of contact between the company and its customers. Responsible for promoting and selling products or services to potential and existing customers.',
    'Account Executive:Focuses on acquiring and managing key customer accounts. Develops and maintains long-term relationships with clients.',
    'Sales Manager:Oversees and manages the sales team. Sets sales targets and develops strategies to achieve them. Provides leadership, coaching, and support to sales representatives.',
    "Business Development Representative (BDR) / Sales Development Representative (SDR):Focuses on generating leads and qualifying potential customers. Initiates contact with prospects to create interest in the company's products or services.",
    'Sales Operations Analyst:Supports the sales team by providing data analysis and insights. Manages sales processes, tools, and systems to improve efficiency.',
    'Account Manager:Manages relationships with existing clients to ensure satisfaction and retention. Identifies upselling and cross-selling opportunities.',
    'Sales Engineer:Combines technical knowledge with sales skills to provide pre-sales support. Assists in product demonstrations and technical presentations.',
    'Channel Sales Manager:Manages relationships with channel partners (distributors, resellers, etc.). Develops and implements strategies to increase sales through channels.',
    'Inside Sales Representative:Conducts sales activities remotely, often through phone calls and virtual meetings. Focuses on selling to prospects and existing customers.',
    'Regional Sales Manager:Manages sales activities within a specific geographic region. Develops and executes regional sales strategies.',
    "Sales Enablement Specialist:Supports the sales team by providing training, resources, and tools. Ensures that the sales team is equipped to effectively sell the company's products or services.",
    'Customer Success Manager:Focuses on ensuring customer satisfaction and maximizing customer retention. Works closely with clients to understand their needs and address any concerns.',
    'Sales Trainer:Provides training to the sales team on product knowledge, sales techniques, and company processes. Ensures that the sales team is well-equipped to succeed in their roles.',
  ],
  Marketing: [
    'Marketing Manager:Oversees the entire marketing department. Develops marketing strategies and plans. Sets goals and objectives for the team.',
    "Brand Manager:Manages and promotes the company's brand. Develops brand strategies. Ensures brand consistency across all channels.",
    'Product Manager:Manages a specific product or product line. Develops product strategies. Collaborates with other teams for product development.',
    'Digital Marketing Manager:Manages digital marketing campaigns. Oversees online advertising, social media, and content marketing. Analyzes digital marketing metrics.',
    'Content Marketing Manager:Develops and implements content strategies. Manages content creation and distribution. Ensures content aligns with overall marketing goals.',
    "Social Media Manager:Manages the company's social media accounts. Develops social media strategies. Creates and schedules social media content.",
    'SEO Specialist:Optimizes website content for search engines. Implements SEO strategies to improve website visibility. Analyzes and reports on SEO performance.',
    'Paid Media Specialist:Manages paid advertising campaigns (PPC). Optimizes ad performance. Monitors and analyzes advertising metrics.',
    'Public Relations (PR) Specialist:Manages public relations and communication strategies. Develops press releases and communication materials. Cultivates relationships with media outlets.',
    'Event Marketing Manager:Plans and executes marketing events. Coordinates sponsorships and partnerships. Measures the success of events.',
    'Market Research Analyst:Conducts market research to identify trends and opportunities. Analyzes data to provide insights. Assists in making informed marketing decisions.',
    'Email Marketing Manager:Manages email campaigns. Develops email marketing strategies. Analyzes email marketing metrics.',
    'Graphic Designer (Marketing):Creates visual content for marketing materials. Designs promotional materials. Ensures visual consistency with the brand.',
    'Marketing Analyst:Analyzes marketing data and performance. Provides insights and recommendations. Assists in making data-driven decisions.',
    'Influencer Marketing Manager:Manages relationships with influencers. Develops and implements influencer marketing strategies. Measures the impact of influencer campaigns.',
  ],
  Communication: [
    'Public Relations Specialist:Develops and maintains a positive public image for the organization. Creates press releases, organizes events, and manages media relations.',
    'Corporate Communications Manager:Oversees internal and external communication strategies. Manages communication with stakeholders, employees, and the public.',
    'Marketing Communications Coordinator:Coordinates marketing efforts and ensures consistent messaging across various channels. Creates marketing materials, including brochures, newsletters, and advertisements.',
    "Social Media Manager:Manages the organization's presence on social media platforms. Creates and curates content, engages with the audience, and monitors social media trends.",
    'Content Writer/Strategist:Creates written content for various purposes, including websites, blogs, and marketing materials. Develops content strategies to achieve communication goals.',
    'Internal Communications Specialist:Manages communication within the organization. Develops and implements strategies to keep employees informed and engaged.',
    'Media Relations Specialist:Builds and maintains relationships with journalists and media outlets. Coordinates interviews and press conferences.',
    "Speechwriter:Writes speeches and presentations for executives or public figures. Ensures that the message aligns with the speaker's voice and organizational goals.",
    'Event Coordinator:Plans and coordinates events, such as conferences, product launches, and corporate gatherings. Manages communication related to event logistics.',
    'Customer Communications Specialist:Creates communications targeted at customers, including newsletters and product updates. Addresses customer inquiries and concerns.',
    'Communications Trainer:Provides training on effective communication skills for individuals or teams. Offers coaching on public speaking, writing, and interpersonal communication.',
    'Digital Communications Manager:Oversees digital communication channels, including websites, email campaigns, and online platforms. Implements strategies to enhance digital communication effectiveness.',
    "Brand Ambassador:Represents and promotes the organization's brand. Engages with the public and communicates the brand's values and messages.",
    'Multimedia Specialist:Creates visual content, including graphics, videos, and animations. Enhances communication through multimedia elements.',
    'Communication Analyst:Analyzes communication strategies and their effectiveness. Provides insights to improve communication processes.',
  ],
};

const onboardingConfigData = {
  categories: [
    {
      uid: '915fff9f-41c6-4531-bc4a-69702797560a',
      name: 'Software Developer',
      isVisible: true,
      imageUrl: '',
      subCategories: [
        {
          uid: 'f60a0d90-3d38-400d-805d-0630a732f36b',
          name: 'Web Developer',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: 'fa7b4157-5f31-438e-8b9e-d9c81065d7f2',
              name: 'HTML',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9178344b-3583-48d9-9cbf-60100e511ce3',
              name: 'Angular js',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: 'a12ed4ab-57db-4326-8862-3393d8e16cde',
          name: 'Java Developer',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '5225e9e5-3f62-4fc6-a11b-4044636d5064',
              name: 'Java',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '1ec1d926-a73b-4e88-b19b-51c76fed87a2',
              name: 'Redis',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '078184e7-b28b-4fd7-946a-6696a503b505',
              name: 'Nodejs',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '551f0de2-6876-4d68-ad0f-a2dbbe784763',
              name: 'MongoDB',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
      ],
    },
    {
      uid: '4479f64b-0fea-4bf5-a156-f7967aaf9e62',
      name: 'Devops',
      isVisible: true,
      imageUrl: '',
      subCategories: [
        {
          uid: '61b10bce-ab0d-4ca6-b773-d9410e8e25b8',
          name: 'Engineer I',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '75ea1dd9-6dd0-405f-9149-8a7ced1b3d6e',
              name: 'Skill 3',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '767e01f9-a977-4219-a97a-164bc6a94aff',
              name: 'Skill 30',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7db9dd89-0d4f-4a1f-95ec-3440213798f8',
              name: 'Skill 26',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '85222f70-2890-4ecb-b330-a961ddfedce6',
              name: 'Skill 10',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '974696a7-c3d5-4906-aef9-1adc5271d880',
              name: 'Skill 17',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '98c071d8-00fb-4402-a2ad-9719ca1fa97b',
              name: 'Skill 27',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9dd11c29-1866-40f1-a360-7a054c969770',
              name: 'Skill 18',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'af03f5dd-848e-4daa-9253-642dd75777b6',
              name: 'Skill 22',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b063aad7-ada5-4015-9d13-37065bec3671',
              name: 'Skill 15',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b6c80604-4d7f-4d04-973d-3209ce3df437',
              name: 'Skill 11',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c174a8e4-f636-44c5-873c-3184bb779852',
              name: 'Skill 20',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd27a208f-6161-466f-9b96-d831fc68da86',
              name: 'Skill 24',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd6d66360-c705-4b42-b037-679f70f56038',
              name: 'Skill 8',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e114d2c6-1fc8-439c-92f8-bb41cd13d82f',
              name: 'Skill 25',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e1cbd1e8-873c-44da-9218-9b610ea7b316',
              name: 'Skill 19',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f3709ed5-adba-4bca-b2a6-432d716e4792',
              name: 'Skill 7',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f8645f93-989e-4588-b583-4d2e898eb55e',
              name: 'Skill 21',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'fe2e813c-bfb7-4096-94d2-720aa7586748',
              name: 'Skill 34',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'feb99b4e-ce0f-4c07-a795-767453e6b9b7',
              name: 'Skill 29',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: '49b26ea4-451b-4d33-be6f-65eb2c3013f9',
          name: 'Engineer II',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '75ea1dd9-6dd0-405f-9149-8a7ced1b3d6e',
              name: 'Skill 3',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '767e01f9-a977-4219-a97a-164bc6a94aff',
              name: 'Skill 30',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7db9dd89-0d4f-4a1f-95ec-3440213798f8',
              name: 'Skill 26',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '85222f70-2890-4ecb-b330-a961ddfedce6',
              name: 'Skill 10',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '974696a7-c3d5-4906-aef9-1adc5271d880',
              name: 'Skill 17',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '98c071d8-00fb-4402-a2ad-9719ca1fa97b',
              name: 'Skill 27',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9dd11c29-1866-40f1-a360-7a054c969770',
              name: 'Skill 18',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'af03f5dd-848e-4daa-9253-642dd75777b6',
              name: 'Skill 22',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b063aad7-ada5-4015-9d13-37065bec3671',
              name: 'Skill 15',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b6c80604-4d7f-4d04-973d-3209ce3df437',
              name: 'Skill 11',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c174a8e4-f636-44c5-873c-3184bb779852',
              name: 'Skill 20',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd27a208f-6161-466f-9b96-d831fc68da86',
              name: 'Skill 24',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd6d66360-c705-4b42-b037-679f70f56038',
              name: 'Skill 8',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e114d2c6-1fc8-439c-92f8-bb41cd13d82f',
              name: 'Skill 25',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e1cbd1e8-873c-44da-9218-9b610ea7b316',
              name: 'Skill 19',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f3709ed5-adba-4bca-b2a6-432d716e4792',
              name: 'Skill 7',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f8645f93-989e-4588-b583-4d2e898eb55e',
              name: 'Skill 21',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'fe2e813c-bfb7-4096-94d2-720aa7586748',
              name: 'Skill 34',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'feb99b4e-ce0f-4c07-a795-767453e6b9b7',
              name: 'Skill 29',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: '99e9e79c-6f72-4f6e-8d6c-63ab82872407',
          name: 'Engineer III',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '75ea1dd9-6dd0-405f-9149-8a7ced1b3d6e',
              name: 'Skill 3',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '767e01f9-a977-4219-a97a-164bc6a94aff',
              name: 'Skill 30',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7db9dd89-0d4f-4a1f-95ec-3440213798f8',
              name: 'Skill 26',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '85222f70-2890-4ecb-b330-a961ddfedce6',
              name: 'Skill 10',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '974696a7-c3d5-4906-aef9-1adc5271d880',
              name: 'Skill 17',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '98c071d8-00fb-4402-a2ad-9719ca1fa97b',
              name: 'Skill 27',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9dd11c29-1866-40f1-a360-7a054c969770',
              name: 'Skill 18',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'af03f5dd-848e-4daa-9253-642dd75777b6',
              name: 'Skill 22',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b063aad7-ada5-4015-9d13-37065bec3671',
              name: 'Skill 15',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b6c80604-4d7f-4d04-973d-3209ce3df437',
              name: 'Skill 11',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c174a8e4-f636-44c5-873c-3184bb779852',
              name: 'Skill 20',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd27a208f-6161-466f-9b96-d831fc68da86',
              name: 'Skill 24',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd6d66360-c705-4b42-b037-679f70f56038',
              name: 'Skill 8',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e114d2c6-1fc8-439c-92f8-bb41cd13d82f',
              name: 'Skill 25',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e1cbd1e8-873c-44da-9218-9b610ea7b316',
              name: 'Skill 19',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f3709ed5-adba-4bca-b2a6-432d716e4792',
              name: 'Skill 7',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f8645f93-989e-4588-b583-4d2e898eb55e',
              name: 'Skill 21',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'fe2e813c-bfb7-4096-94d2-720aa7586748',
              name: 'Skill 34',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'feb99b4e-ce0f-4c07-a795-767453e6b9b7',
              name: 'Skill 29',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: 'f677455c-da59-425f-874c-8d33692f24cb',
          name: 'Engineer IV',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '75ea1dd9-6dd0-405f-9149-8a7ced1b3d6e',
              name: 'Skill 3',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '767e01f9-a977-4219-a97a-164bc6a94aff',
              name: 'Skill 30',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7db9dd89-0d4f-4a1f-95ec-3440213798f8',
              name: 'Skill 26',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '85222f70-2890-4ecb-b330-a961ddfedce6',
              name: 'Skill 10',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '974696a7-c3d5-4906-aef9-1adc5271d880',
              name: 'Skill 17',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '98c071d8-00fb-4402-a2ad-9719ca1fa97b',
              name: 'Skill 27',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9dd11c29-1866-40f1-a360-7a054c969770',
              name: 'Skill 18',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'af03f5dd-848e-4daa-9253-642dd75777b6',
              name: 'Skill 22',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b063aad7-ada5-4015-9d13-37065bec3671',
              name: 'Skill 15',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b6c80604-4d7f-4d04-973d-3209ce3df437',
              name: 'Skill 11',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c174a8e4-f636-44c5-873c-3184bb779852',
              name: 'Skill 20',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd27a208f-6161-466f-9b96-d831fc68da86',
              name: 'Skill 24',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd6d66360-c705-4b42-b037-679f70f56038',
              name: 'Skill 8',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e114d2c6-1fc8-439c-92f8-bb41cd13d82f',
              name: 'Skill 25',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e1cbd1e8-873c-44da-9218-9b610ea7b316',
              name: 'Skill 19',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f3709ed5-adba-4bca-b2a6-432d716e4792',
              name: 'Skill 7',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f8645f93-989e-4588-b583-4d2e898eb55e',
              name: 'Skill 21',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'fe2e813c-bfb7-4096-94d2-720aa7586748',
              name: 'Skill 34',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'feb99b4e-ce0f-4c07-a795-767453e6b9b7',
              name: 'Skill 29',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: 'de119a45-8beb-46cd-a5de-1ab40bb5fb18',
          name: 'Engineer V',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '75ea1dd9-6dd0-405f-9149-8a7ced1b3d6e',
              name: 'Skill 3',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '767e01f9-a977-4219-a97a-164bc6a94aff',
              name: 'Skill 30',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7db9dd89-0d4f-4a1f-95ec-3440213798f8',
              name: 'Skill 26',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '85222f70-2890-4ecb-b330-a961ddfedce6',
              name: 'Skill 10',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '974696a7-c3d5-4906-aef9-1adc5271d880',
              name: 'Skill 17',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '98c071d8-00fb-4402-a2ad-9719ca1fa97b',
              name: 'Skill 27',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9dd11c29-1866-40f1-a360-7a054c969770',
              name: 'Skill 18',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'af03f5dd-848e-4daa-9253-642dd75777b6',
              name: 'Skill 22',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b063aad7-ada5-4015-9d13-37065bec3671',
              name: 'Skill 15',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b6c80604-4d7f-4d04-973d-3209ce3df437',
              name: 'Skill 11',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c174a8e4-f636-44c5-873c-3184bb779852',
              name: 'Skill 20',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd27a208f-6161-466f-9b96-d831fc68da86',
              name: 'Skill 24',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd6d66360-c705-4b42-b037-679f70f56038',
              name: 'Skill 8',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e114d2c6-1fc8-439c-92f8-bb41cd13d82f',
              name: 'Skill 25',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e1cbd1e8-873c-44da-9218-9b610ea7b316',
              name: 'Skill 19',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f3709ed5-adba-4bca-b2a6-432d716e4792',
              name: 'Skill 7',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'f8645f93-989e-4588-b583-4d2e898eb55e',
              name: 'Skill 21',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'fe2e813c-bfb7-4096-94d2-720aa7586748',
              name: 'Skill 34',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'feb99b4e-ce0f-4c07-a795-767453e6b9b7',
              name: 'Skill 29',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
      ],
    },
    {
      uid: '76002cad-4394-49f0-82f4-e290ac419874',
      name: 'Business Analyst',
      isVisible: true,
      imageUrl: '',
      subCategories: [
        {
          uid: 'a41e7092-a5bf-406d-b691-8ef64a753010',
          name: 'BA 1',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '6f0d4d20-72fc-4726-a2a5-4a4179653c20',
              name: 'Skill 101',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7552d065-da99-4510-8f39-e9f9c3734311',
              name: 'Skill 129',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7b6a737e-31a5-41e7-a952-03b31ebe2a95',
              name: 'Skill 125',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '873b5f69-945d-4984-8bc4-ec4fcaf53c4a',
              name: 'Skill 122',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '877a1c2f-1769-454e-ad1c-ec6a0915a3b2',
              name: 'Skill 114',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '8a013e37-3775-4424-94e4-c8b919d2a75c',
              name: 'Skill 127',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9054d302-29f7-49f2-95fe-15036c626a2e',
              name: 'Skill 128',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9c36c48a-0504-4b0b-b30b-5137a310ae37',
              name: 'Skill 117',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'a4703224-bf09-4f32-9907-833932fc628e',
              name: 'Skill 116',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b019a51f-e755-412a-bbb7-94cc213686fc',
              name: 'Skill 120',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b75914de-c7db-41ee-a335-2a83ee9bc803',
              name: 'Skill 123',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b862b2b2-c5fa-440d-9a19-07209da12ee4',
              name: 'Skill 126',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c7da68f6-f982-4edc-9ed8-5df9d0137a4e',
              name: 'Skill 106',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'cd7420f8-3146-40eb-84e1-679213529ac3',
              name: 'Skill 109',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'cd7420f8-3146-40eb-84e1-679213529ac3',
              name: 'Skill 109',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd2a52f3d-1b27-4161-bde3-c3542d6ce0cf',
              name: 'Skill 107',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'df590cb9-18b9-4990-83f1-e7c962c70b3e',
              name: 'Skill 110',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e03a0924-1f17-4f71-b5bb-c39218784da5',
              name: 'Skill 108',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'ef364328-15ce-4bbf-8a18-96f3b74a7e54',
              name: 'Skill 114',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
        {
          uid: 'ca98281b-707b-426f-9054-f5ca57a48179',
          name: 'BA 2',
          isVisible: true,
          imageUrl: '',
          skills: [
            {
              uid: '6f0d4d20-72fc-4726-a2a5-4a4179653c20',
              name: 'Skill 101',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7552d065-da99-4510-8f39-e9f9c3734311',
              name: 'Skill 129',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '7b6a737e-31a5-41e7-a952-03b31ebe2a95',
              name: 'Skill 125',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '873b5f69-945d-4984-8bc4-ec4fcaf53c4a',
              name: 'Skill 122',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '877a1c2f-1769-454e-ad1c-ec6a0915a3b2',
              name: 'Skill 114',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '8a013e37-3775-4424-94e4-c8b919d2a75c',
              name: 'Skill 127',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9054d302-29f7-49f2-95fe-15036c626a2e',
              name: 'Skill 128',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: '9c36c48a-0504-4b0b-b30b-5137a310ae37',
              name: 'Skill 117',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'a4703224-bf09-4f32-9907-833932fc628e',
              name: 'Skill 116',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b019a51f-e755-412a-bbb7-94cc213686fc',
              name: 'Skill 120',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b75914de-c7db-41ee-a335-2a83ee9bc803',
              name: 'Skill 123',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'b862b2b2-c5fa-440d-9a19-07209da12ee4',
              name: 'Skill 126',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'c7da68f6-f982-4edc-9ed8-5df9d0137a4e',
              name: 'Skill 106',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'cd7420f8-3146-40eb-84e1-679213529ac3',
              name: 'Skill 109',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'cd7420f8-3146-40eb-84e1-679213529ac3',
              name: 'Skill 109',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'd2a52f3d-1b27-4161-bde3-c3542d6ce0cf',
              name: 'Skill 107',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'df590cb9-18b9-4990-83f1-e7c962c70b3e',
              name: 'Skill 110',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'e03a0924-1f17-4f71-b5bb-c39218784da5',
              name: 'Skill 108',
              isVisible: true,
              imageUrl: '',
            },
            {
              uid: 'ef364328-15ce-4bbf-8a18-96f3b74a7e54',
              name: 'Skill 114',
              isVisible: true,
              imageUrl: '',
            },
          ],
        },
      ],
    },
  ],
};

export class ApiService {
  static request(url: string, options: RequestInit = {}): Promise<Response> {
    options.headers = {
      'content-type': 'application/json',
      ...(options.headers || {}),
    };
    return fetch(url, options).catch((err) => {
      return Promise.reject(err);
    });
  }

  static signup(payload: SignUpPayload) {
    payload.isdCode =
      countries.find((item) => item.code === payload.country)?.dial_code ||
      '+1';
    return ApiService.request(ENDPOINTS.CANDIDATE_SIGNUP, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  static login(payload: LoginPayload) {
    return ApiService.request(ENDPOINTS.CANDIDATE_LOGIN, {
      body: JSON.stringify({
        emailId: payload.emailId,
        password: payload.password,
      }),
      method: REST_METHODS.POST,
    });
  }

  static clientLogin(payload: LoginPayload) {
    return ApiService.request(ENDPOINTS.CLIENT_LOGIN, {
      body: JSON.stringify({
        emailId: payload.emailId,
        password: payload.password,
      }),
      method: REST_METHODS.POST,
    });
  }

  static adminLogin(payload: LoginPayload) {
    return ApiService.request(ENDPOINTS.ADMIN_LOGIN, {
      body: JSON.stringify({
        emailId: payload.emailId,
        password: payload.password,
      }),
      method: REST_METHODS.POST,
    });
  }

  static candidateLogout() {
    return ApiService.request(ENDPOINTS.CANDIDATE_LOGOUT, {
      method: REST_METHODS.POST,
    });
  }

  // static logout(payload: LogoutPayload) {
  static logout() {
    return ApiService.request(ENDPOINTS.CLIENT_LOGOUT, {
      // body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static forgotPassword(payload: ForgotPasswordPayload) {
    return ApiService.request(ENDPOINTS.FORGOT_PASSWORD, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static resetPassword(payload: ResetPasswordPayload) {
    return ApiService.request(ENDPOINTS.RESET_PASSWORD, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static changePassword(payload: changePasswordPayload) {
    return ApiService.request(ENDPOINTS.CHANGE_PASSWORD, {
      body: JSON.stringify(payload),
      method: REST_METHODS.PUT,
    });
  }

  static completeSignup(payload: CompleteSignupPayload) {
    return ApiService.request(ENDPOINTS.CANDIDATE_OTP_VERIFICATION, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static clientSignup(payload: ClientSignUpPayload) {
    payload.isdCode =
      countries.find((item) => item.code === payload.country)?.dial_code ||
      '+1';
    return ApiService.request(ENDPOINTS.CLIENT_SIGNUP, {
      body: JSON.stringify({ ...payload, emailId: payload.emailId }),
      method: REST_METHODS.POST,
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  static completeClientSignup(payload: CompleteSignupPayload) {
    return ApiService.request(ENDPOINTS.COMPLETE_CLIENT_SIGNUP, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static verifyOTP(payload: VerifyOTPPayload) {
    return ApiService.request(ENDPOINTS.VERIFY_OTP, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static resendOTP(payload: ResendOTPPayload) {
    return ApiService.request(ENDPOINTS.RESEND_OTP, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  // static fetchCandidateOnboardingConfig() {
  //   return ApiService.request(ENDPOINTS.CANDIDATE_ONBOARDING_CONFIG, {
  //     method: REST_METHODS.GET,
  //   });
  // }

  static fetchCandidateOnboardingConfig(): Promise<Response> {
    // Simulating an asynchronous API call
    return new Promise((resolve) => {
      setTimeout(() => {
        const json = { data: onboardingConfigData };
        resolve({
          json: () => Promise.resolve(json),
        } as Response);
      }, 1000); // Simulating a delay of 1 second
    });
  }

  static fetchCategoryData(): Promise<Response> {
    // Simulating an asynchronous API call
    return new Promise((resolve) => {
      setTimeout(() => {
        const json = { data: jobCategoryDetails };
        resolve({
          json: () => Promise.resolve(json),
        } as Response);
      }, 1000); // Simulating a delay of 1 second
    });
  }

  static saveCandidateProfile(body: CandidateProfile) {
    return ApiService.request(ENDPOINTS.CANDIDATE_PROFILE, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static saveCandidateSkillData(body: CandidateSkillPayload) {
    return ApiService.request(`${ENDPOINTS.CANDIDATE_PROFILE}/skills`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static fetchCandidateProfile() {
    return ApiService.request(ENDPOINTS.CANDIDATE_PROFILE, {
      method: REST_METHODS.GET,
    });
  }

  static saveClientProfile(body: ClientProfilePayloadData) {
    return ApiService.request(ENDPOINTS.CLIENT_PROFILE, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static saveClientOnboardingProfile(body: ClientProfilePayloadData) {
    return ApiService.request(ENDPOINTS.CLIENT_ONBOARDING_PROFILE, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static saveCompanyOnboardingDetails(body: onboardingProfilePayloadData) {
    return ApiService.request(ENDPOINTS.CLIENT_ONBOARDING_PROFILE, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static fetchClientProfile() {
    return ApiService.request(ENDPOINTS.CLIENT_PROFILE, {
      method: REST_METHODS.GET,
    });
  }

  static saveClientPersonalDetails(body: ClientPersonalData) {
    return ApiService.request(ENDPOINTS.CLIENT_PERSONAL_DETAILS, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static saveClientOrganizationDetails(body: ClientOrganizationPayloadData) {
    return ApiService.request(ENDPOINTS.CLIENT_ORG_DETAILS, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static fetchClientOnboardingProfile() {
    return ApiService.request(ENDPOINTS.CLIENT_ONBOARDING_PROFILE, {
      method: REST_METHODS.GET,
    });
  }

  static saveJobDetails(body: ClientJobPostPayload) {
    return ApiService.request(ENDPOINTS.CLIENT_JOB_CREATION, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static saveJobDetailsForBackStep(body: ClientJobPayloadForback) {
    return ApiService.request(ENDPOINTS.CLIENT_JOB_CREATION, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static editExistingJobDetails(body: existingJobPayload) {
    return ApiService.request(ENDPOINTS.CLIENT_JOB_CREATION, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static fetchClientJobList() {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB_LIST}?pageNumber=1&pageLength=15`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static fetchJobDetailsByJobID(jobID: string) {
    return ApiService.request(`${ENDPOINTS.CLIENT_JOB_LIST}/${jobID}`, {
      method: REST_METHODS.GET,
    });
  }

  static fetchClientJobListByParams(queryParams: string) {
    return ApiService.request(`${ENDPOINTS.CLIENT_JOB_LIST}?${queryParams}`, {
      method: REST_METHODS.GET,
    });
  }

  static sendInvitationLink(payload: ClinetInvitationPayload) {
    return ApiService.request(ENDPOINTS.ADD_NEW_TEAM_MEMBER, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static updateInvitationLink(payload: ClinetUpdatedInvitationPayload) {
    return ApiService.request(ENDPOINTS.UPDATE_TEAM_MEMBER, {
      body: JSON.stringify(payload),
      method: REST_METHODS.PUT,
    });
  }

  static resendInvitationLink(userUid: string) {
    return ApiService.request(
      `${ENDPOINTS.RESEND_TEAM_MEMBER_INVITE}?userUid=${userUid}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static removeTeammate(userId: string) {
    return ApiService.request(`${ENDPOINTS.DELETE_TEAM_MEMBER}`, {
      body: JSON.stringify(userId),
      method: REST_METHODS.DELETE,
    });
  }

  static toggleTeammateStatus(userUid: string, status: 'DISABLE' | 'ENABLE') {
    return ApiService.request(`${ENDPOINTS.TOGGLE_TEAM_MEMBER}=${status}`, {
      body: JSON.stringify(userUid),
      method: REST_METHODS.PUT,
    });
  }

  static updatePOCInMyTeam(newUserUid: string) {
    return ApiService.request(`${ENDPOINTS.UPDATE_POC_DETAILS}`, {
      body: JSON.stringify({ newUserUid }),
      method: REST_METHODS.PUT,
    });
  }

  static updatePrimeAdminInMyTeam(newUserUid: string) {
    return ApiService.request(`${ENDPOINTS.UPDATE_PRIME_ADMIN_DETAILS}`, {
      body: JSON.stringify({ newUserUid }),
      method: REST_METHODS.PUT,
    });
  }

  static getMyTeamList() {
    return ApiService.request(`${ENDPOINTS.MY_TEAM}`, {
      method: REST_METHODS.GET,
    });
  }

  static validateLink(token: string) {
    return ApiService.request(
      `${ENDPOINTS.INVITE_LINK_VALIDITY}?token=${token}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getUserAuthentication() {
    return ApiService.request(`${ENDPOINTS.USER_AUTHENTICATE}`, {
      method: REST_METHODS.GET,
    });
  }

  static getCandidateAuthentication() {
    return ApiService.request(`${ENDPOINTS.CANDIDATE_AUTHENTICATE}`, {
      method: REST_METHODS.GET,
    });
  }

  static getAdminAuthentication() {
    return ApiService.request(`${ENDPOINTS.ADMIN_AUTHENTICATE}`, {
      method: REST_METHODS.GET,
    });
  }

  static deleteExistingJob(id: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB_LIST}/${Number(id)}?forceDelete=true`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  }

  // static getInterviewerPreferredSlots() {
  //   return ApiService.request(`${ENDPOINTS.INTERVIEWER_PREFERRED_SLOTS}`, {
  //     method: REST_METHODS.GET,
  //   });
  // }

  // static saveInterviewerPreferredSlots(body: any) {
  //   return ApiService.request(`${ENDPOINTS.INTERVIEWER_PREFERRED_SLOTS}`, {
  //     method: REST_METHODS.PUT,
  //     body: JSON.stringify(body),
  //   });
  // }

  static saveClientInterviewerPreferredSlots(body: any) {
    return ApiService.request(`${ENDPOINTS.CLIENT_PREFERRED_SLOTS}`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static fetchCandidateInvites() {
    return ApiService.request(ENDPOINTS.CANDIDATE_INVITES, {
      method: REST_METHODS.GET,
    });
  }

  static fetchInvite(inviteID: number) {
    return ApiService.request(ENDPOINTS.CANDIDATE_INVITES + `/${inviteID}`, {
      method: REST_METHODS.GET,
    });
  }

  static deleteInterviewInvitation(invitationID: string) {
    return ApiService.request(ENDPOINTS.DELETE_INVITE + `/${invitationID}`, {
      method: REST_METHODS.DELETE,
    });
  }

  static fetchTestCenters() {
    //const params = `?inviteId=${inviteID}`;
    return ApiService.request(ENDPOINTS.TEST_CENTERS, {
      method: REST_METHODS.GET,
      // body: JSON.stringify({ invitationToken })
    });
  }

  static fetchCenterAvailability(
    invitationId?: string,
    cubeID?: number,
    timeZoneId?: string
  ) {
    let params = '';
    if (cubeID) {
      params = `?invitationId=${invitationId}&testCentreId=${cubeID}`;
    } else {
      params = `?invitationId=${invitationId}&timeZoneId=${timeZoneId}`;
    }

    return ApiService.request(ENDPOINTS.CENTER_AVAILABILITY + params, {
      method: REST_METHODS.GET,
    });
  }

  static fetchCenterAvailabilityForReschedule(
    interviewId?: string,
    cubeID?: number,
    timeZoneId?: string
  ) {
    let params = '';
    if (cubeID) {
      params = `?interviewId=${interviewId}&testCentreId=${cubeID}`;
    } else {
      params = `?interviewId=${interviewId}&timeZoneId=${timeZoneId}`;
    }

    return ApiService.request(ENDPOINTS.CENTER_AVAILABILITY + params, {
      method: REST_METHODS.GET,
    });
  }

  static validateInterview(interviewID: string | undefined) {
    return ApiService.request(ENDPOINTS.BOOKINGS + `/${interviewID}`, {
      method: REST_METHODS.GET,
    });
  }

  static scheduleInterview(
    invitationId: string,
    date: string,
    time: string,
    testCentreId?: number,
    timeZoneId?: string
  ) {
    let body = {};
    if (testCentreId) {
      body = { invitationId, testCentreId, date, time };
    } else {
      body = { invitationId, timeZoneId, date, time };
    }
    return ApiService.request(ENDPOINTS.BOOKINGS, {
      method: REST_METHODS.POST,
      body: JSON.stringify(body),
    });
  }

  static rescheduleInterview(
    bookingID: string,
    testCentreId: number,
    date: string,
    time: string,
    timeZoneId?: string
  ) {
    let body = {};
    if (testCentreId) {
      body = { testCentreId, date, time };
    } else {
      body = { timeZoneId, date, time };
    }
    return ApiService.request(ENDPOINTS.BOOKINGS + `/${bookingID}`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(body),
    });
  }

  static deleteCandidateInterview(interviewID: string) {
    return ApiService.request(ENDPOINTS.BOOKINGS + `/${interviewID}`, {
      method: REST_METHODS.DELETE,
    });
  }

  static fetchCandidateInterviews() {
    return ApiService.request(ENDPOINTS.CANDIDATE_BOOKINGS, {
      method: REST_METHODS.GET,
    });
  }

  static uploadFile(payload: uploadFileData) {
    return ApiService.request(ENDPOINTS.UPLOAD_FILE, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }
  static uploadFileToPreSignedURL(payload: payloadForPreSignedURL) {
    return ApiService.request(payload.preSignedUrl, {
      body: JSON.stringify(payload.formData),
      method: REST_METHODS.PUT,
    });
  }

  static fetchUserPayment(
    currentPage: number,
    recordsPerPage: number,
    fromDate?: string,
    toDate?: string
  ) {
    let url = `${ENDPOINTS.PAYMENT}?pageNumber=${currentPage}&pageLength=${recordsPerPage}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}%2009%3A01%3A15`;
    }
    if (toDate) {
      url += `&toDate=${toDate}%2009%3A01%3A15`;
    }
    return ApiService.request(url, {
      method: REST_METHODS.GET,
    });
  }

  static getUserPayment() {
    return ApiService.request(
      `${ENDPOINTS.PAYMENT}?pageNumber=1&pageLength=10`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static initiatePaymentCheckout(payload: {
    amount: number;
    paymentGateway: string;
  }) {
    return ApiService.request(ENDPOINTS.PAYMENT_CHECKOUT, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static paymentSuccessStatus = (sessionId: string, pathname: string) => {
    return ApiService.request(
      `${ENDPOINTS.PAYMENT_STATUS}/${pathname}/${sessionId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  };

  static fetchTransactionDetails = (
    currentPage: number,
    transactionsType: string,
    recordsPerPage: number,
    fromDate?: string,
    toDate?: string
  ) => {
    let url = `${ENDPOINTS.TRANSACTIONS}?transactionType=${transactionsType}&pageNumber=${currentPage}&pageLength=${recordsPerPage}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}%2000%3A00%3A00`;
    }

    if (toDate) {
      url += `&toDate=${toDate}%2023%3A59%3A00`;
    }
    return ApiService.request(url, {
      method: REST_METHODS.GET,
    });
  };

  static personaVerification = (payload: string, environmentId: string) => {
    return ApiService.request(ENDPOINTS.PERSONA_VERIFICATION, {
      method: REST_METHODS.POST,
      body: JSON.stringify({ personaInquiryId: payload, env: environmentId }),
    });
  };

  static fetchAllJobInterviewRound(jobId: string) {
    return ApiService.request(
      `${ENDPOINTS.JOB_INTERVIEW_ALLROUND}${jobId}/interviewRounds`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static fetchJobInterviewRoundById(jobId: string, roundId: number) {
    return ApiService.request(
      `${ENDPOINTS.JOB_INTERVIEW_ALLROUND}${jobId}/interviewRound/${roundId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static shuffleInterviewRounds(data: any, jobId: string) {
    const body = { interviewRoundOrder: data };
    return ApiService.request(
      `${ENDPOINTS.JOB_INTERVIEW_ALLROUND}${jobId}/interviewRounds/`,
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(body),
      }
    );
  }

  static fetchActiveUser() {
    return ApiService.request(ENDPOINTS.ACTIVE_USERS, {
      method: REST_METHODS.GET,
    });
  }

  static getCandidateProfileDetails(sectionUrl: string) {
    return ApiService.request(ENDPOINTS.CANDIDATE_PROFILE + '/' + sectionUrl, {
      method: REST_METHODS.GET,
    });
  }

  static updateCandidateProfileDetails(
    sectionUrl: string,
    uid: string | undefined,
    sectionData: { [index: string]: any }
  ) {
    return ApiService.request(
      ENDPOINTS.CANDIDATE_PROFILE +
        (uid ? `/${sectionUrl}/${uid}` : `/${sectionUrl}`),
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(sectionData),
      }
    );
  }

  static addCandidateProfileDetails(
    sectionUrl: string,
    sectionData: { [index: string]: any }
  ) {
    return ApiService.request(ENDPOINTS.CANDIDATE_PROFILE + '/' + sectionUrl, {
      method: REST_METHODS.POST,
      body: JSON.stringify(sectionData),
    });
  }

  static deleteCandidateProfileDetails(sectionUrl: string, uid: string) {
    return ApiService.request(
      ENDPOINTS.CANDIDATE_PROFILE + '/' + sectionUrl + '/' + uid,
      {
        method: REST_METHODS.DELETE,
        body: JSON.stringify({ uid: uid }),
      }
    );
  }

  static fetchCandidatePersonalDetails() {
    return ApiService.request(ENDPOINTS.CANDIDATE_PERSONAL_DETAILS, {
      method: REST_METHODS.GET,
    });
  }

  static submitFeedBack(feedBack: string, reaction: string) {
    const payload = {
      feedback: feedBack,
      userReaction: reaction,
    };
    return ApiService.request(ENDPOINTS.SUBMIT_FEEDBACK, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static interviewerUpcomingInterviews() {
    return ApiService.request(ENDPOINTS.UPCOMING_INTERVIEWS, {
      method: REST_METHODS.GET,
    });
  }
  static createTest(testName: string, testInstructions: string) {
    return ApiService.request(ENDPOINTS.CREATE_TEST, {
      method: REST_METHODS.PUT,
      body: JSON.stringify({ testName, testInstructions }),
    });
  }

  static getAllClientTests() {
    return ApiService.request(ENDPOINTS.CLIENT_TESTS, {
      method: REST_METHODS.GET,
    });
  }

  static deleteTest(testUid: string) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}`, {
      method: REST_METHODS.DELETE,
    });
  }

  static publishTest(testUid: string) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}/publish`, {
      method: REST_METHODS.POST,
    });
  }

  static getTestDetails(testUid: string | null | undefined) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}`, {
      method: REST_METHODS.GET,
    });
  }

  static cloneTest(
    testUid: string,
    testName: string,
    testInstructions: string
  ) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/clone`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify({ testUid, testName, testInstructions }),
    });
  }

  static updateTest(
    testUid: string,
    testName: string,
    testInstructions: string
  ) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/update`, {
      method: REST_METHODS.POST,
      body: JSON.stringify({ testUid, testName, testInstructions }),
    });
  }

  static addQuestion(testUid: string, question: any) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}/question`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(question),
    });
  }

  static deleteQuestion(testUid: string, questionUid: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_TESTS}/${testUid}/question/${questionUid}`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  }

  static updateQuestion(testUid: string, question: any) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}/question`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(question),
    });
  }

  static getAISuggestedQuestions(questionRequestData: {
    topic: string;
    expertise: string;
    contextId?: string;
  }) {
    let queryString = `expertise=${questionRequestData?.expertise}&topic=${questionRequestData?.topic}`;
    if (questionRequestData?.contextId) {
      queryString = `${queryString}&contextId=${questionRequestData?.contextId}`;
    }
    return ApiService.request(`${ENDPOINTS.AITESTQUESTIONS}?${queryString}`, {
      method: REST_METHODS.GET,
    });
  }

  static shuffleQuestions(testUid: string, questionsOrder: any) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testUid}/shuffle`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(questionsOrder),
    });
  }

  static getInterviewTestDetails(interviewId: string) {
    return ApiService.request(
      `${ENDPOINTS.CANDIDATE_INTERVIEW_TEST}/${interviewId}/getTestDetails`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static startTest(testAssignmentId: string) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/start`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static setTestEndTime(testAssignmentId: string, endTime: number) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/setEndTime?endTimeInUTC=${endTime}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static getQuestionDetails(testAssignmentId: string, questionNumber: number) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/question/${questionNumber}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static submitAnswer(
    testAssignmentId: string,
    questionNumber: number,
    questionType: string,
    answer: AnswerObjectType
  ) {
    if (questionType !== 'CODING') {
      return ApiService.request(
        `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/question/${questionNumber}`,
        {
          method: REST_METHODS.POST,
          body: JSON.stringify({ answer: answer?.answer }),
        }
      );
    } else {
      return ApiService.request(
        `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/question/${questionNumber}`,
        {
          method: REST_METHODS.POST,
          body: JSON.stringify({
            answer: answer?.answer,
            codingLanguage: answer?.codingLanguage,
          }),
        }
      );
    }
  }

  static markForLater(testAssignmentId: string, questionNumber: number) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/question/${questionNumber}/toggle`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static markCurrentQuestion(testAssignmentId: string, questionNumber: number) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/question/${questionNumber}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static getTestStatus(testAssignmentId: string) {
    return ApiService.request(`${ENDPOINTS.ASSESSMENT}/${testAssignmentId}`, {
      method: REST_METHODS.GET,
    });
  }

  static interviewerFeedback() {
    return ApiService.request(ENDPOINTS.INTERVIEWER_FEEDBACK, {
      method: REST_METHODS.GET,
    });
  }

  static submitTest(testAssignmentId: string) {
    return ApiService.request(
      `${ENDPOINTS.ASSESSMENT}/${testAssignmentId}/submit`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static viewProfile(jobId: number) {
    return ApiService.request(`${ENDPOINTS.VIEW_JOB_PROFILE}?jobId=${jobId}`, {
      method: REST_METHODS.GET,
    });
  }

  static workProfile(candidateUid: string) {
    return ApiService.request(
      `${ENDPOINTS.VIEW_WORK_PROFILE}?candidateUid=${candidateUid}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static interviewerMarkAvailability(
    option: string,
    interviewPanelUid: string
  ) {
    const payload = {
      interviewPanelUid: interviewPanelUid,
      interviewAvailability: option,
    };
    return ApiService.request(ENDPOINTS.INTERVIEWER_MARK_AVAILABILITY, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static candidateLanguage() {
    return ApiService.request(ENDPOINTS.CANDIDATE_LANGUAGE, {
      method: REST_METHODS.GET,
    });
  }

  static candidateWorkExperience() {
    return ApiService.request(ENDPOINTS.CANDIDATE_WORKEXPERIENCE, {
      method: REST_METHODS.GET,
    });
  }

  static candidateEducation() {
    return ApiService.request(ENDPOINTS.CANDIDATE_EDUCATION, {
      method: REST_METHODS.GET,
    });
  }

  static candidateCertification() {
    return ApiService.request(ENDPOINTS.CANDIDATE_CERTIFICATION, {
      method: REST_METHODS.GET,
    });
  }

  static interviewFeedBack(feedbackUid: string) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_FEEDBACK}/${feedbackUid}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static markFeedbackStatus(feedbackUid: string, feedbackStatus: string) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_FEEDBACK}/${feedbackUid}?feedbackStatus=${feedbackStatus}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static interviewerSubmitFeedback(payload: any) {
    return ApiService.request(ENDPOINTS.INTERVIEW_FEEDBACK, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static submitInterviewFeedback(payload: any) {
    return ApiService.request(ENDPOINTS.INTERVIEW_FEEDBACK, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(payload),
    });
  }

  static fetchActiveMembers() {
    return ApiService.request(ENDPOINTS.CLIENT_ACTIVE_USER, {
      method: REST_METHODS.GET,
    });
  }

  static uploadInterviewRoundData = (
    jobID: string,
    body: createInterviewRoundPayload,
    roundId: number
  ) => {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/interviewRound/${roundId}`,
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(body),
      }
    );
  };

  static setCubeForRound = (jobID: number, roundId: number) => {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/interviewRound/${roundId}`,
      {
        method: REST_METHODS.POST,
      }
    );
  };

  static getFeedbackConfigSkills(jobRole: string, interviewRound: string) {
    return ApiService.request(
      `${ENDPOINTS.FEEDBACK_CONFIG_SKILLS}?role=${jobRole}&interviewRoundName=${interviewRound}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getmoreFeedbackConfigSkills(
    jobRole: string,
    interviewRound: string,
    contextId: string
  ) {
    return ApiService.request(
      `${ENDPOINTS.FEEDBACK_CONFIG_SKILLS}?role=${jobRole}&interviewRoundName=${interviewRound}&contextId=${contextId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getAllTestList() {
    return ApiService.request(ENDPOINTS.CLIENT_TESTS, {
      method: REST_METHODS.GET,
    });
  }

  static CreateRoundId(jobID: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/
      
      ${jobID}/interviewRound`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static deleteInterviewRound(jobID: string, roundId: number) {
    return ApiService.request(
      `${ENDPOINTS.JOB_INTERVIEW_ALLROUND}${jobID}/interviewRound/${roundId}`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  }

  static fetchShortlistedCandidates(jobID: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/shortlistedCandidate`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static fetchEligibleShortlistedCandidates(jobID: string, roundName: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/shortlistedCandidates/eligibleForInterviewRound?interviewRoundName=${roundName}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getTestDetailById(testId: string) {
    return ApiService.request(`${ENDPOINTS.CLIENT_TESTS}/${testId}`, {
      method: REST_METHODS.GET,
    });
  }

  static getPanelCommonSlots(timeZoneId: string, panel: string[]) {
    return ApiService.request(
      `${ENDPOINTS.PANEL_COMMON_TIMESLOTS}?panel=${panel}&timeZoneId=${timeZoneId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static sendInterviewInvitation(body: InterviewInviteForCandidatesPayload) {
    return ApiService.request(ENDPOINTS.INTERVIEW_INVITE_TO_CANDIDATES, {
      method: REST_METHODS.POST,
      body: JSON.stringify(body),
    });
  }

  static getInterviewInvitationData(token: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_INVITE_TO_CANDIDATES}?token=${token}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static addShortlistedCandidates = (
    body: shortListedmailIdsList,
    jobID: string
  ) => {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/shortlistedCandidate`,
      {
        method: REST_METHODS.POST,
        body: JSON.stringify(body),
      }
    );
  };

  static toggleShortlistedCandidateFavourite = (
    candID: number,
    jobID: string
  ) => {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/shortlistedCandidates/${candID}`,
      {
        method: REST_METHODS.POST,
      }
    );
  };

  static deleteShortlistedCandidate = (
    candID: number,
    jobID: string,
    forceDelete: boolean
  ) => {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobID}/shortlistedCandidate/${candID}?forceDelete=${forceDelete}`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  };

  static getCandidateInterviews() {
    return ApiService.request(ENDPOINTS.CANDIDATE_MYINTERVIEWS, {
      method: REST_METHODS.GET,
    });
  }

  static async getCandidateProfilePercentage() {
    const endpoints = [
      'personalDetails',
      'professionalSummary',
      'skills',
      'education',
      'workExperience',
      'certification',
      'language',
    ];

    const responses = await Promise.all(
      endpoints.map(async (endpoint) => {
        const response = await ApiService.getCandidateProfileDetails(endpoint);
        return response.json();
      })
    );
    const totalSections = endpoints.length;
    let completedSections = 0;
    responses.forEach((data) => {
      if (data?.data?.length !== 0) {
        completedSections++;
      }
    });
    const percentage = Math.ceil((completedSections / totalSections) * 100);
    return percentage;
  }

  static personaNameCorrection() {
    return ApiService.request(ENDPOINTS.PERSONA_NAME_CORRECTION, {
      method: REST_METHODS.POST,
    });
  }

  static getJobRoleData(jobDescription: string, descriptionType: string) {
    return ApiService.request(
      `${ENDPOINTS.AI_JOB_ROLES}?desc=${jobDescription}&descType=${descriptionType}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getCandidateJobs() {
    return ApiService.request(ENDPOINTS.CANDIDATE_MYJOBS, {
      method: REST_METHODS.GET,
    });
  }

  static getCandidateInterviewRounds(jobId: string) {
    return ApiService.request(
      `${ENDPOINTS.CANDIDATE_MYJOBS}/${jobId}/interviewRounds`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getSkillData(jobRole: string, contextId?: string) {
    let params = '';
    if (contextId) {
      params = `skills?role=${jobRole}&contextId=${contextId}`;
    } else {
      params = `skills?role=${jobRole}`;
    }
    return ApiService.request(`${ENDPOINTS.AI_JOB_ROLES}/${params}`, {
      method: REST_METHODS.GET,
    });
  }

  static fetchClientUpcomingInterviews() {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_DETAILS}/upcomingInterviews`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getJobDescriptionFromAI(
    subCategory: string,
    coreSkills: any,
    addnSkills: any
  ) {
    return ApiService.request(`${ENDPOINTS.AI_JOB_DESCRIPTION}`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify({
        subCategory,
        coreSkills,
        addnSkills,
      }),
    });
  }
  static clientPendingInvitations() {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_DETAILS}/pendingInvitations`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static clientPendingFeedbacks() {
    return ApiService.request(`${ENDPOINTS.CLIENT_DETAILS}/pendingFeedbacks`, {
      method: REST_METHODS.GET,
    });
  }

  static clientFeedbacks(interviewId: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/interview/${interviewId}/feedbacks/`,
      {
        method: REST_METHODS.GET,
      }
    );
  }
  static requestFeedback(jobId: number, interviewId: string) {
    return ApiService.request(
      `${ENDPOINTS.CANDIDATE_MYJOBS}/interviewRounds/requestFeedback`,
      {
        method: REST_METHODS.POST,
        body: JSON.stringify({
          jobId,
          interviewId,
        }),
      }
    );
  }

  static clientSubmitFeedBack(
    payload: InterviewFeedbackType,
    interviewId: string
  ) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/interview/${interviewId}/decision`,
      {
        method: REST_METHODS.POST,
        body: JSON.stringify(payload),
      }
    );
  }

  static getClientValidateInterviewInvitationData(token: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_INVITE_TO_CANDIDATES}/clientValidation?token=${token}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static clientResendInterviewInvitation(token: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_INVITE_TO_CANDIDATES}/${token}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static getCandidateInterviewRoundDetails(
    roundId: string,
    candidateId: string
  ) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${roundId}/shortlistedCandidates/${candidateId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getCandidateDetailusingMailId(emailId: string) {
    return ApiService.request(
      `${ENDPOINTS.VIEW_WORK_PROFILE}?candidateEmailId=${emailId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static cancelInterview(interviewId: string) {
    return ApiService.request(`${ENDPOINTS.BOOKINGS}/${interviewId}`, {
      method: REST_METHODS.DELETE,
    });
  }

  static needCubeSelectionAfterInvitation(invitationId: string) {
    return ApiService.request(
      `${ENDPOINTS.INTERVIEW_INVITE_TO_CANDIDATES}/${invitationId}/setCube`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static deleteCandidateBooking(interviewID: string) {
    return ApiService.request(ENDPOINTS.BOOKINGS + `/${interviewID}`, {
      method: REST_METHODS.DELETE,
    });
  }

  // static getCandidateDetailusingMailId = (emailId:string) => {
  //   return ApiService.request(`${ENDPOINTS.VIEW_WORK_PROFILE}?candidateEmailId= ${emailId}`),{
  //     method:REST_METHODS.GET
  //   }
  // }

  static hiringCandidateService(
    jobId: string,
    candidateId: number,
    jobStatus: hiringStatus,
    forceUpdate: boolean
  ) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/${jobId}/shortlistedCandidate/${candidateId}?status=${jobStatus}&forceUpdate=${forceUpdate}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static feedBackReminder(feedbackUid: string) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/interviewFeedback/${feedbackUid}/reminder`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static JoinInterviewDetails(
    interviewId: string | undefined,
    userType: string
  ) {
    return ApiService.request(
      `${ENDPOINTS.JOIN_INTERVIEW}/${interviewId}/${userType}/join`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static interviewMeetingDetails(interviewId: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/interview/getMeetingDetails?interviewId=${interviewId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getJoinInterviewFeedbackDetils(interviewId: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB}/interviewerFeedback/${interviewId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static postJoinInterviewCandidateVerification(
    interviewId: string | undefined | null,
    verified: boolean
  ) {
    return ApiService.request(
      `${ENDPOINTS.JOIN_INTERVIEW_CAN_VERIFICATION}/${interviewId}/candidateVerification?status=${verified}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static getCandidateVerificationStatus(interviewId: string | undefined) {
    return ApiService.request(
      `${ENDPOINTS.JOIN_INTERVIEW}/${interviewId}/candidateVerification`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static initiatePodEntry(payload: PodEntryInitiate) {
    return ApiService.request(`${ENDPOINTS.POD_ENTRY}/initiate`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static validatePodEntryByOTP(payload: validatePodOTP) {
    return ApiService.request(`${ENDPOINTS.POD_ENTRY}/complete`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }

  static getAdminTestCentres() {
    return ApiService.request(`${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}`, {
      method: REST_METHODS.GET,
    });
  }

  static addTestCentre(testCentreData: any) {
    return ApiService.request(`${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(testCentreData),
    });
  }

  static getTestCenterDetails(testCentreId: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}/${testCentreId}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static updateTestCentreStatus(testCentreId: string, status: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}/${testCentreId}/status?status=${status}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static updateTestCentre(dataToUpdate: any, testCentreId: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}/${testCentreId}`,
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(dataToUpdate),
      }
    );
  }

  static updateTestCentreInventory(dataToUpdate: any, testCentreId: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}/${testCentreId}/inventory`,
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(dataToUpdate),
      }
    );
  }

  static deleteTestCentreInventory(testCentreId: string, assetId: any) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_TEST_CENTRES_ENDPOINT}/${testCentreId}/inventory?assetUid=${assetId}`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  }

  static getAllPods() {
    return ApiService.request(`${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pods`, {
      method: REST_METHODS.GET,
    });
  }

  static addPod(podData: { testCentreId: string; podType: string }) {
    return ApiService.request(`${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(podData),
    });
  }

  static updatePodStatus(podId: string, status: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod/${podId}/statusUpdate?status=${status}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static getPodDetails(podId: string) {
    return ApiService.request(`${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod/${podId}`, {
      method: REST_METHODS.GET,
    });
  }

  static getPodsInTestCentre(testCentreId: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_PODS_ENDPOINT}/${testCentreId}/pods`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static updatePodDetails(dataToUpdate: any, podId: string) {
    return ApiService.request(`${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod/${podId}`, {
      method: REST_METHODS.PUT,
      body: JSON.stringify(dataToUpdate),
    });
  }

  static updatePodInventory(dataToUpdate: any, podId: string) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod/${podId}/inventory`,
      {
        method: REST_METHODS.PUT,
        body: JSON.stringify(dataToUpdate),
      }
    );
  }

  static deletePodInventory(podId: string, assetId: any) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_PODS_ENDPOINT}/pod/${podId}/inventory?assetUid=${assetId}`,
      {
        method: REST_METHODS.DELETE,
      }
    );
  }

  static getBookingsByTestCentreId(
    testCentreId: string,
    pageNumber: number,
    pageLength: number
  ) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_BOOKINGS_ENDPOINT}?testCentreId=${testCentreId}&pageNumber=${pageNumber}&pageLength=${pageLength}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }
  static getBookingsByPodId(
    podId: string,
    pageNumber: number,
    pageLength: number
  ) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_BOOKINGS_ENDPOINT}?podId=${podId}&pageNumber=${pageNumber}&pageLength=${pageLength}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static registerNewClient(payloadData: any) {
    return ApiService.request(`${ENDPOINTS.REGISTER_NEW_CLIENT}`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payloadData),
    });
  }

  static completeLoginForClientAtAccountSelectionStep(payloadData: {
    loginInteractionId: string;
    clientId: string;
  }) {
    return ApiService.request(`${ENDPOINTS.CLIENT_LOGIN}/complete`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payloadData),
    });
  }

  static switchClientAccount() {
    return ApiService.request(`${ENDPOINTS.CLIENT_SWITCH_ACCOUNT}`, {
      method: REST_METHODS.GET,
    });
  }

  static updateJobStatus(jobId: string, status: string, forceUpdate?: boolean) {
    return ApiService.request(
      `${ENDPOINTS.CLIENT_JOB_LIST}/${jobId}/updateStatus?status=${status}&forceUpdate=${forceUpdate}`,
      {
        method: REST_METHODS.PUT,
      }
    );
  }

  static getSubscriptionPlanDetails() {
    return ApiService.request(ENDPOINTS.CLIENT_SUBSCRIPTION_DETAILS, {
      method: REST_METHODS.GET,
    });
  }

  static cancelSubscription() {
    return ApiService.request(ENDPOINTS.CANCEL_CLIENT_SUBSCRIPTION, {
      method: REST_METHODS.POST,
    });
  }

  static subscribePlan(id: number) {
    return ApiService.request(`${ENDPOINTS.SUBSCRIBE_PLAN}/${id}/subscribe`, {
      method: REST_METHODS.POST,
    });
  }

  static confirmSubscription(interactionId: string) {
    return ApiService.request(
      `${ENDPOINTS.CONFIRM_SUBSCRIPTION}?interactionId=${interactionId}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static setupPayment() {
    return ApiService.request(ENDPOINTS.SUBSCRIPTION_PAYMENT_SETUP, {
      method: REST_METHODS.POST,
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  static cubeHourPaymentSetup() {
    return ApiService.request(ENDPOINTS.CUBE_HOUR_PAYMENT_SETUP, {
      method: REST_METHODS.POST,
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  // subscription payment setup success redirect

  static paymentSetupSuccessRedirect(params: string) {
    return ApiService.request(
      `${ENDPOINTS.SUBSCRIPTION_PAYMENT_SETUP_REDIRECT}?session_id=${params}`,
      {
        method: REST_METHODS.POST,
        headers: {
          'content-type': 'application/json',
        },
      }
    );
  }

  static getSubscriptionUsage() {
    return ApiService.request(ENDPOINTS.CLIENT_SUBSCRIPTION_USAGE, {
      method: REST_METHODS.GET,
    });
  }

  static getCubeTimeUsage() {
    return ApiService.request(ENDPOINTS.CUBE_TIME_USAGE, {
      method: REST_METHODS.GET,
    });
  }

  static duplicateCandidateResendOtp(
    loginInteractionId: string,
    otpInteractionId: string
  ) {
    return ApiService.request(`${ENDPOINTS.DUPLICATE_CAN_ENDPOINT}/resend`, {
      method: REST_METHODS.POST,
      body: JSON.stringify({ loginInteractionId, otpInteractionId }),
    });
  }

  static submitContactRequest(payloadData: any) {
    return ApiService.request(`${ENDPOINTS.CONTACT_US}`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payloadData),
    });
  }

  static duplicateCandidateSendOtp(
    loginInteractionId: string,
    emailId: string
  ) {
    return ApiService.request(`${ENDPOINTS?.DUPLICATE_CAN_ENDPOINT}/send`, {
      method: REST_METHODS.POST,
      body: JSON.stringify({ loginInteractionId, emailId }),
    });
  }

  static duplicateCandidateVerifyOtp(payload: VerifyOTPPayload) {
    return ApiService.request(
      `${ENDPOINTS.DUPLICATE_CAN_ENDPOINT}/verification`,
      {
        body: JSON.stringify(payload),
        method: REST_METHODS.POST,
      }
    );
  }

  static resolveDuplicateCandidate(payload: {
    loginInteractionId: string;
    primaryAccountEmailId: string;
    otpInteractionIds: string[];
  }) {
    return ApiService.request(`${ENDPOINTS.RESOLVE_CAN_DUPLICATE_ACCOUNT}`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payload),
    });
  }
  static getInviteData(podId: string, pageNumber: number, pageLength: number) {
    return ApiService.request(
      `${ENDPOINTS.ADMIN_BOOKINGS_ENDPOINT}?podId=${podId}&pageNumber=${pageNumber}&pageLength=${pageLength}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static getCubeTimeDetails() {
    return ApiService.request(ENDPOINTS.CUBE_TIME_DETAILS, {
      method: REST_METHODS.GET,
    });
  }

  static buyCubeTimeBasedOnOffer(payload: { offerId: number }) {
    return ApiService.request(`${ENDPOINTS.BUY_CUBE_TIME}`, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static buyCubeTimeBasedOnHours(payload: {
    cubeHoursInMins: number;
    currency: string;
  }) {
    return ApiService.request(`${ENDPOINTS.BUY_CUBE_TIME}`, {
      body: JSON.stringify(payload),
      method: REST_METHODS.POST,
    });
  }

  static buyCubeTimeOnExistingPaymentSetup(stripeIntId: string) {
    return ApiService.request(
      `${ENDPOINTS.CONFIRM_CUBE_TIME_BUY}${stripeIntId}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static stripePaymentCheckoutSession(stripeIntId: string) {
    return ApiService.request(
      `${ENDPOINTS.CUBE_TIME_CHECK_OUT_SESSION}=${stripeIntId}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static stripePaymentSuccessRedirect(params: string) {
    return ApiService.request(
      `${ENDPOINTS.CUBE_TIME_CHECK_OUT_SUCCESS}?session_id=${params}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static payAsYouGoForCubeHours(status: 'enable' | 'disable' | '') {
    return ApiService.request(`${ENDPOINTS.PAY_AS_YOU_GO}${status}`, {
      method: REST_METHODS.POST,
    });
  }

  static requestCubeInstallation(payloadData: {
    latitude: string | null;
    longitude: string | null;
    comment: string | null;
  }) {
    return ApiService.request(`${ENDPOINTS.REQUEST_CUBE}`, {
      method: REST_METHODS.POST,
      body: JSON.stringify(payloadData),
    });
  }

  static validateMeeting(meetingId: string | null) {
    return ApiService.request(
      `${ENDPOINTS.JOIN_INTERVIEW}/validate?meetingId=${Number(meetingId)}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }
  static getJoinInterviewTestDetails(interviewId: string | null) {
    return ApiService.request(
      `${ENDPOINTS.JOIN_INTERVIEW}/${interviewId}/getTestDetails`,
      {
        method: REST_METHODS.GET,
      }
    );
  }
  static getBackendAdminBooking(
    pageNumber: number,
    pageLength: number,
    params: string
  ) {
    return ApiService.request(
      `${ENDPOINTS.BACKEND_ADMIN_BOOKING_LIST}?pageNumber=${pageNumber}&pageLength=${pageLength}${params}`,
      {
        method: REST_METHODS.GET,
      }
    );
  }

  static unLockPod(unlockInteractionId: string) {
    return ApiService.request(
      `${ENDPOINTS.POD_ENTRY}/unlock/${unlockInteractionId}`,
      {
        method: REST_METHODS.POST,
      }
    );
  }

  static podBookingCompletion(payLoad: {
    unlockInteractionId: string;
    bookingId: string;
    longitude: number | null;
    latitude: number | null;
  }) {
    return ApiService.request(
      `${ENDPOINTS.POD_ENTRY}/booking_selection/complete`,
      { method: REST_METHODS.POST, body: JSON.stringify(payLoad) }
    );
  }
}
