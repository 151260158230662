export const ENDPOINTS = {
  CANDIDATE_SIGNUP: `/api/v1/CANDIDATE/user/register`,
  LOGIN: `/api/v1/login`,
  CLIENT_LOGIN: `/api/v1/CLIENT/user/login`,
  CANDIDATE_LOGIN: `/api/v1/CANDIDATE/user/login`,
  CLIENT_LOGOUT: `/api/v1/CLIENT/user/logout`,
  CANDIDATE_LOGOUT: `/api/v1/CANDIDATE/user/logout`,
  LOGOUT: `/api/v1/logout`,
  ADMIN_LOGIN: `/api/v1/ADMIN/user/login`,
  FORGOT_PASSWORD: `/api/v1/user/forget-password`,
  RESET_PASSWORD: `/api/v1/user/reset-password`,
  CHANGE_PASSWORD: `/api/v1/user/change-password`,
  CANDIDATE_OTP_VERIFICATION: `/api/v1/CANDIDATE/user/register/otpVerify`,
  VERIFY_OTP: `/api/v1/otp/email/verification`,
  RESEND_OTP: `/api/v1/otp/email/resend`,
  CANDIDATE_ONBOARDING_CONFIG: `/api/v1/candidate/onboarding/config`,
  CANDIDATE_PROFILE: `/api/v1/candidate/profile`,
  // CLIENT_SIGNUP: `/api/v1/client/signup/initiate`,
  CLIENT_SIGNUP: `/api/v1/CLIENT/user/register`,
  // COMPLETE_CLIENT_SIGNUP: `/api/v1/client/signup/complete`,
  COMPLETE_CLIENT_SIGNUP: `/api/v1/CLIENT/user/register/otpVerify`,
  CLIENT_PROFILE: `/api/v1/client/user/profile`,
  CLIENT_PERSONAL_DETAILS: `/api/v1/client/user/personalDetails`,
  CLIENT_ORG_DETAILS: `/api/v1/client/details`,
  CLIENT_ONBOARDING_PROFILE: `/api/v1/CLIENT/user/onboardingProfile`,
  REGISTER_NEW_CLIENT: `/api/v1/CLIENT/user/register/newClient`,

  CLIENT_JOB_CREATION: `/api/v1/jobs/build`,
  CLIENT_JOB_LIST: `/api/v1/jobs`,
  INVITE_LINK_VALIDITY: `/api/v1/invite`,
  MY_TEAM: `/api/v1/CLIENT/myTeam`,
  ADD_NEW_TEAM_MEMBER: `/api/v1/CLIENT/team/member/new`,
  UPDATE_TEAM_MEMBER: `/api/v1/CLIENT/team/member/update`,
  RESEND_TEAM_MEMBER_INVITE: `/api/v1/CLIENT/team/member/resendInvite`,
  TOGGLE_TEAM_MEMBER: `/api/v1/CLIENT/team/member/toggleAccess?accessType`,
  UPDATE_POC_DETAILS: `/api/v1/CLIENT/team/POC/update`,
  UPDATE_PRIME_ADMIN_DETAILS: `/api/v1/CLIENT/team/primeAdmin/update`,
  DELETE_TEAM_MEMBER: `/api/v1/CLIENT/team/member/remove`,
  USER_AUTHENTICATE: `/api/v1/CLIENT/user/authenticate`,
  CANDIDATE_AUTHENTICATE: `/api/v1/CANDIDATE/user/authenticate`,
  ADMIN_AUTHENTICATE: `/api/v1/ADMIN/user/authenticate`,
  // INTERVIEWER_PREFERRED_SLOTS: `/api/v1/interviewer/preferredslots`,
  CLIENT_PREFERRED_SLOTS: `/api/v1/client/user/preferredSlots`,
  CANDIDATE_INVITES: `/api/v1/candidate/myInvites`,
  DELETE_INVITE: `/api/v1/job/interviewInvitation`,
  CANDIDATE_BOOKINGS: `/api/v1/candidate/myInterviews`,
  BOOKINGS: `/api/v1/job/interview`,
  TEST_CENTERS: `/api/v1/job/interview/testCentres`,
  CENTER_AVAILABILITY: `/api/v1/job/interview/availableSlots`,
  UPLOAD_FILE: `/api/v1/s3upload`,
  PAYMENT: `/api/v1/payments`,
  PAYMENT_CHECKOUT: `/api/v1/payments/checkout`,
  PAYMENT_STATUS: `/api/v1/`,
  TRANSACTIONS: `/api/v1/transactions`,
  PERSONA_VERIFICATION: `/api/v1/user/persona/Verification`,
  JOB_INTERVIEW_ALLROUND: `/api/v1/job/`,
  ACTIVE_USERS: `/api/v1/client/user/activeUsers`,
  CANDIDATE_PERSONAL_DETAILS: `/api/v1/candidate/profile/personalDetails`,
  SUBMIT_FEEDBACK: `/api/v1/appFeedback/submit`,
  UPCOMING_INTERVIEWS: `/api/v1/client/user/upcomingInterviews`,
  CREATE_TEST: `/api/v1/test/create`,
  CLIENT_TESTS: `/api/v1/test`,
  CANDIDATE_INTERVIEW_TEST: `/api/v1/interview`,
  AITESTQUESTIONS: `/api/v1/engine/ai/testAssignmentQuestions`,
  ASSESSMENT: `/api/v1/assessment/test`,
  // INTERVIEWER_FEEDBACK: `/api/v1/interviewer/interviewFeedback`,
  INTERVIEWER_FEEDBACK: `/api/v1/client/user/pendingFeedbacks`,
  VIEW_JOB_PROFILE: `/api/v1/jobs/viewProfile/`,
  VIEW_WORK_PROFILE: `/api/v1/candidate/workProfile`,
  CLIENT_JOB: `/api/v1/job`,
  CLIENT_ACTIVE_USER: `/api/v1/client/user/activeUsers`,
  PANEL_COMMON_TIMESLOTS: `/api/v1/job/interviewInvitation/panelCommonTimeSlot`,
  INTERVIEW_INVITE_TO_CANDIDATES: `/api/v1/job/interviewInvitation`,
  FEEDBACK_CONFIG_SKILLS: `/api/v1/engine/ai/feedbackConfig/skills`,
  INTERVIEWER_MARK_AVAILABILITY: `/api/v1/client/user/interview/markAvailability`,
  CANDIDATE_LANGUAGE: `/api/v1/candidate/profile/language`,
  CANDIDATE_WORKEXPERIENCE: `/api/v1/candidate/profile/workExperience`,
  CANDIDATE_EDUCATION: `/api/v1/candidate/profile/education`,
  CANDIDATE_CERTIFICATION: `/api/v1/candidate/profile/certification`,
  INTERVIEW_FEEDBACK: `/api/v1/job/interviewFeedback`,
  CANDIDATE_MYINTERVIEWS: `/api/v1/candidate/myInterviews`,
  PERSONA_NAME_CORRECTION: `/api/v1/user/persona/nameCorrection`,
  AI_JOB_ROLES: `/api/v1/engine/ai/jobRoles`,
  AI_JOB_DESCRIPTION: `/api/v1/engine/ai/jobDescription`,
  CANDIDATE_MYJOBS: `/api/v1/candidate/jobs`,
  CLIENT_DETAILS: `/api/v1/client/user`,
  JOIN_INTERVIEW: `/api/v1/interview`,
  JOIN_INTERVIEW_CAN_VERIFICATION: `/api/v1/interview`,
  POD_ENTRY: `/api/v1/pod/entry`,
  ADMIN_TEST_CENTRES_ENDPOINT: `/api/v1/testcentres`,
  ADMIN_PODS_ENDPOINT: `/api/v1/testcentre`,
  ADMIN_BOOKINGS_ENDPOINT: `/api/v1/admin/bookings`,
  CLIENT_SWITCH_ACCOUNT: `/api/v1/CLIENT/user/switchAccount`,
  CLIENT_SUBSCRIPTION_DETAILS: `/api/v1/CLIENT/payments/subscription/details`,
  CANCEL_CLIENT_SUBSCRIPTION: `/api/v1/CLIENT/payments/plan/cancel/subscription`,
  SUBSCRIBE_PLAN: `/api/v1/CLIENT/payments/plan`,
  CONFIRM_SUBSCRIPTION: `/api/v1/CLIENT/payments/plan/confirm/subscription`,
  SUBSCRIPTION_PAYMENT_SETUP: `/api/v1/CLIENT/payments/set-up?init=SUBSCRIPTION`,
  SUBSCRIPTION_PAYMENT_SETUP_REDIRECT: `/api/v1/CLIENT/payments/set-up/redirect/success`,
  CLIENT_SUBSCRIPTION_USAGE: `/api/v1/CLIENT/payments/subscription/usage`,

  CUBE_TIME_DETAILS: `/api/v1/CLIENT/payments/cube-time/details`,
  CUBE_TIME_USAGE: `/api/v1/CLIENT/payments/cube-time/usage`,
  BUY_CUBE_TIME: `/api/v1/CLIENT/payments/cube-time/buy/initiate`,
  CONFIRM_CUBE_TIME_BUY: `/api/v1/CLIENT/payments/cube-time/buy?interactionId=`,
  CUBE_TIME_CHECK_OUT_SESSION: `/api/v1/CLIENT/payments/cube-time/buy/check-out-session?interactionId`,
  CUBE_TIME_CHECK_OUT_SUCCESS: `/api/v1/CLIENT/payments/cube-time/buy/redirect/success`,
  PAY_AS_YOU_GO: `/api/v1/CLIENT/payments/payAsYouGo?status=`,
  CUBE_HOUR_PAYMENT_SETUP: `/api/v1/CLIENT/payments/set-up?init=CUBE`,
  DUPLICATE_CAN_ENDPOINT: `/api/v1/otp/email`,
  RESOLVE_CAN_DUPLICATE_ACCOUNT: `/api/v1/CANDIDATE/user/resolve-duplicate`,
  CONTACT_US: `/api/v1/add/contactedUser`,
  REQUEST_CUBE: `/api/v1/cube/installation/request`,
  BACKEND_ADMIN_BOOKING_LIST: `/api/v1/admin/bookings`,
};
