import { CubeDetails } from '@/src/services/types';
import { GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CubeMarker from './CubeMarker';
import {
  CustomLightMapStyles,
  CustomMapStyles,
  USLatLngBounds,
} from './MapConfig';

function MapComponent({
  allCubes,
  selectedCube,
  selectCube,
  controlledAddress,
  controlledCenter,
  controlledBounds,
  setError,
  onMapClick,
  setSearchResult,
  isDarkTheme = false,
}: {
  allCubes: CubeDetails[];
  selectedCube?: CubeDetails | undefined;
  selectCube: CallableFunction;
  setError?: CallableFunction;
  controlledAddress: string;
  controlledCenter: google.maps.LatLng | undefined;
  controlledBounds: google.maps.LatLngBounds | undefined;
  onMapClick: VoidFunction;
  isDarkTheme?: boolean;
  setSearchResult?: (
    arg: { label: string; value: string }[] | undefined | null
  ) => void;
}) {
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [zoomLevel, setZoomLevel] = useState<number>(14);
  const [isAutoMoving, setIsAutoMoving] = useState<boolean>(false);

  useEffect(() => {
    if (!map) return;
    if (!controlledCenter && !controlledBounds) return;

    setIsAutoMoving(true);
    if (controlledBounds !== undefined && controlledCenter !== undefined) {
      map.fitBounds(controlledBounds);
      map.panTo(controlledCenter);
    } else if (controlledBounds !== undefined) {
      map.fitBounds(controlledBounds);
    } else if (controlledCenter !== undefined) {
      map.setZoom(14);
      map.panTo(controlledCenter);
    }
  }, [controlledCenter, controlledBounds, map]);

  useEffect(() => {
    if (controlledBounds !== undefined) {
      if (hasCubes(controlledBounds)) {
        setError && setError(undefined);
      } else {
        // setError(
        //   `There are no test centers in "${controlledAddress}". Try searching other locations`
        // );
        if (setSearchResult) {
          setSearchResult(undefined);
        }
      }
    }
  }, [controlledBounds]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
    map.fitBounds(USLatLngBounds);
  }, []);

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(undefined);
  }, []);

  const onClick = (e: google.maps.MapMouseEvent) => {
    onMapClick();
    e.stop();
  };

  const onZoomChanged = () => {
    if (!map) return;
    setZoomLevel(map.getZoom() || 14);
  };

  const onIdle = () => {
    if (!map) return;
    if (selectedCube) {
      map.panTo({ lat: selectedCube.latitude, lng: selectedCube.longitude });
    }

    if (isAutoMoving) {
      setIsAutoMoving(false);
    } else {
      checkForCubesInMap();
    }
  };

  const checkForCubesInMap = () => {
    if (!map) return;
    const mapBounds = map.getBounds();
    if (mapBounds !== undefined) {
      if (hasCubes(mapBounds)) {
        setError && setError(undefined);
      } else {
        // setError(
        //   'There are no test centers in this area. Try searching other locations'
        // );
        if (setSearchResult) {
          setSearchResult(undefined);
        }
      }
    }
  };

  const hasCubes = (bounds: google.maps.LatLngBounds) => {
    if (allCubes.length > 0) {
      let cubeInView = false;
      for (const cube of allCubes) {
        const location = { lat: cube.latitude, lng: cube.longitude };
        if (bounds.contains(location)) {
          cubeInView = true;
          break;
        }
      }
      return cubeInView;
    }
    return false;
  };

  return (
    <GoogleMap
      mapContainerStyle={
        selectedCube
          ? { height: '100%', width: '59%' }
          : { height: '100%', width: '100%' }
      }
      options={{
        disableDefaultUI: true,
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: !isDarkTheme ? CustomLightMapStyles : CustomMapStyles,
        maxZoom: 16,
        minZoom: 4,
        restriction: {
          latLngBounds: USLatLngBounds,
        },
      }}
      zoom={zoomLevel}
      onClick={onClick}
      onZoomChanged={onZoomChanged}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={onIdle}
    >
      {allCubes
        .map((c, i) => (
          <CubeMarker
            key={`MAP-MARKER-${i}`}
            map={map}
            zoomLevel={zoomLevel}
            isSelected={!!selectedCube && c.id === selectedCube.id}
            cube={c}
            selectCube={selectCube}
            isDarkTheme={isDarkTheme}
          />
        ))
        .filter((n) => n)}
    </GoogleMap>
  );
}

export const CubesMap = React.memo(MapComponent);
