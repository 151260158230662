import PodType1 from '@/public/images/pod-types/pod-type-1.svg';
import PodType2 from '@/public/images/pod-types/pod-type-2.svg';
import dayjs from 'dayjs';
import moment from 'moment';
import {
  CandidateInterviewDataType,
  InterviewDataType,
  InterviewerUpcomingInterviewsType,
} from './types';

const AVATAR_URLS = [
  '/images/categories/category-1.svg',
  '/images/categories/category-2.svg',
  '/images/categories/category-3.svg',
  '/images/categories/category-4.svg',
  '/images/categories/category-5.svg',
  '/images/categories/category-6.svg',
];

const CATEGORY_IMAGE_URLS = [
  '/images/categories/category-icons/category-1.svg',
  '/images/categories/category-icons/category-2.svg',
  '/images/categories/category-icons/category-3.svg',
  '/images/categories/category-icons/category-4.svg',
];

const SUB_CATEGORY_IMAGE_URLS = [
  '/images/job-sub-categories/sub-category-1.svg',
  '/images/job-sub-categories/sub-category-2.svg',
  '/images/job-sub-categories/sub-category-3.svg',
  '/images/job-sub-categories/sub-category-4.svg',
  '/images/job-sub-categories/sub-category-5.svg',
  '/images/job-sub-categories/sub-category-6.svg',
  '/images/job-sub-categories/sub-category-7.svg',
  '/images/job-sub-categories/sub-category-8.svg',
  '/images/job-sub-categories/sub-category-9.svg',
  '/images/job-sub-categories/sub-category-10.svg',
  '/images/job-sub-categories/sub-category-11.svg',
  '/images/job-sub-categories/sub-category-12.svg',
  '/images/job-sub-categories/sub-category-13.svg',
  '/images/job-sub-categories/sub-category-14.svg',
  '/images/job-sub-categories/sub-category-15.svg',
  '/images/job-sub-categories/sub-category-16.svg',
  '/images/job-sub-categories/sub-category-17.svg',
  '/images/job-sub-categories/sub-category-18.svg',
];

const JOB_ROLES_IMAGE_URLS = [
  '/images/job-sub-categories/frontend.svg',
  '/images/job-sub-categories/backend.svg',
  '/images/job-sub-categories/fullstack.svg',
];

const ICON_BACKGROUNDS = [
  '#5E2E84',
  '#1D7387',
  '#877C1D',
  '#1D8740',
  '#CD4B13',
  '#1356BE',
  '#5E2E84',
];

const SKILL_IMAGE_URLS = [
  '/images/skills/angular.svg',
  '/images/skills/aws.svg',
  '/images/skills/css.svg',
  '/images/skills/javascript.svg',
  '/images/skills/linux.svg',
  '/images/skills/dotnet.svg',
  '/images/skills/nodejs.svg',
  '/images/skills/react.svg',
  '/images/skills/scala.svg',
  '/images/skills/sql.svg',
  '/images/skills/aws.svg',
  '/images/skills/c.svg',
  '/images/skills/cpp.svg',
  '/images/skills/csharp.svg',
  '/images/skills/css3.svg',
  '/images/skills/go.svg',
  '/images/skills/haskell.svg',
  '/images/skills/html.svg',
  '/images/skills/java.svg',
  '/images/skills/javascript.svg',
  '/images/skills/kotlin.svg',
  '/images/skills/lua.svg',
  '/images/skills/net.svg',
  '/images/skills/php.svg',
  '/images/skills/python.svg',
  '/images/skills/r.svg',
  '/images/skills/ruby.svg',
  '/images/skills/typescript.svg',
];

export const INTERVIEW_ROUND_STATUS_DATA: {
  [key: string]: {
    label: string;
    color?: string;
    background?: string;
    border?: string;
  };
} = {
  DONE: {
    background: 'bg-green-500',
    label: 'Done',
  },
  SELECTED: {
    background: 'bg-green-500',
    label: 'Selected',
  },
  IN_PROGRESS: {
    background: 'bg-yellow-500',
    label: 'In Progress',
  },
  REJECTED: {
    label: 'Rejected',
    color: 'text-red-600',
    background: 'bg-red-600',
  },
  INVITATION_SENT: {
    label: 'Invited',
    color: 'text-yellow-500',
  },
  INTERVIEW_SCHEDULED: {
    label: 'Interview Scheduled',
    color: 'text-yellow-500',
  },
  CANDIDATE_ABSENT: {
    label: 'Absent',
    color: 'text-red-600',
  },
  POSITIVE: {
    label: 'Positive',
    color: 'text-green-500',
    border: 'border-green-500',
  },
  NEGATIVE: {
    label: 'Negative',
    color: 'text-red-600',
    border: 'border-red-600',
  },
  NEUTRAL: {
    label: 'Neutral',
    color: 'text-yellow-500',
    border: 'border-yellow-500',
  },
  INTERVIEW_COMPLETED: {
    label: 'Interview Completed',
    color: 'text-green-500',
  },
  INVITATION_EXPIRED: {
    label: 'Invitation Expired',
    color: 'text-zinc-500',
  },
  INTERVIEW_CANCELLED: {
    label: 'Interview Cancelled',
    color: 'text-red-600',
  },
  INVITATION_CANCELLED: {
    label: 'Invitation Cancelled',
    color: 'text-red-600',
  },
  INVITATION_CLOSED: {
    label: 'Invitation Closed',
    color: 'text-red-600',
  },
  INTERVIEW_CLOSED: {
    label: 'Interview Closed',
    color: 'text-red-600',
  },
  INTERVIEW_ABSENT: {
    label: 'Interview Absent',
    color: 'text-red-600',
  },
  FEEDBACK_RECEIVED: {
    label: 'Interview Completed',
    color: 'text-green-500',
  },
};

export const CANDIDATE_JOB_STATUS_DATA: {
  [key: string]: { bgColor: string; label: string };
} = {
  SELECTED: { bgColor: 'bg-green-500', label: 'Selected' },
  REJECTED: { bgColor: 'bg-red-600', label: 'Rejected' },
  IN_PROGRESS: { bgColor: 'bg-yellow-500', label: 'In Progress' },
  SHORTLISTED: { bgColor: 'bg-yellow-500', label: 'Shortlisted' },
  HOLD: { bgColor: 'bg-yellow-500', label: 'On Hold' },
  OFFER_ROLLED_OUT: { bgColor: 'bg-green-500', label: 'Offer Rolled Out' },
  OFFER_ACCEPTED: { bgColor: 'bg-green-500', label: 'Offer Accepted' },
  OFFER_REJECTED: { bgColor: 'bg-red-600', label: 'Offer Rejected' },
};

export const CLIENT_JOB_STATUS_DATA: {
  [key: string]: { style: string; label: string };
} = {
  SELECTED: {
    style: 'bg-green-500 border border-green-500 text-white',
    label: 'Selected',
  },
  REJECTED: {
    style: 'bg-red-600 border border-red-600 text-white',
    label: 'Rejected',
  },
  IN_PROGRESS: {
    style: 'text-yellow-500 border border-yellow-500',
    label: 'In Progress',
  },
  SHORTLISTED: {
    style: 'bg-yellow-500 border border-yellow-500 text-white',
    label: 'Shortlisted',
  },
  HOLD: {
    style: 'bg-yellow-500 border border-yellow-500 text-white',
    label: 'On Hold',
  },
  OFFER_ROLLED_OUT: {
    style: 'bg-green-500 border border-green-500 text-white',
    label: 'Offer Rolled Out',
  },
  OFFER_ACCEPTED: {
    style: 'bg-green-500 border border-green-500 text-white',
    label: 'Offer Accepted',
  },
  OFFER_REJECTED: {
    style: 'bg-red-600 border border-red-500 text-white',
    label: 'Offer Rejected',
  },
};

export const StatusStyles: {
  [index: string]: { label: string; styleClass: string };
} = {
  ACTIVE: {
    label: 'Active',
    styleClass: 'bg-teal-100 text-emerald-600 text-base font-normal',
  },
  INACTIVE: {
    label: 'Inactive',
    styleClass: 'bg-rose-200 text-red-500 text-base font-normal',
  },
  RETIRED: {
    label: 'Retired',
    styleClass: 'bg-gray-200 text-stone-500 text-base font-normal',
  },
};

export const ASSESSMENT_TYPES: { [key: string]: { value: string } } = {
  HYBRID: {
    value: 'Interview + Test',
  },
  TEST_BASED: {
    value: 'Test',
  },
  INTERVIEW_BASED: {
    value: 'Interview',
  },
};

export const suggestedTimeZone = [
  {
    name: 'US/Samoa',
    short: 'US/SST',
  },
  {
    name: 'US/Hawaii',
    short: 'US/HST',
  },
  {
    name: 'US/Alaska',
    short: 'US/AT',
  },
  {
    name: 'US/Pacific',
    short: 'US/PT',
  },
  {
    name: 'US/Mountain',
    short: 'US/MT',
  },
  {
    name: 'US/Central',
    short: 'US/CT',
  },
  {
    name: 'US/Eastern',
    short: 'US/ET',
  },
];

export const PodTypeData = [
  {
    id: 1,
    value: 'TYPE_1',
    label: 'Type01',
    icon: PodType1,
  },
  {
    id: 2,
    value: 'TYPE_2',
    label: 'Type02',
    icon: PodType2,
  },
  // {
  //     id: 3,
  //     value: 'TYPE_3',
  //     label: 'Type03',
  //     icon: PodType3
  // },
  // {
  //     id: 4,
  //     value: 'TYPE_4',
  //     label: 'Type04',
  //     icon: PodType4
  // }
];

export const AssessmentTypes: { [key: string]: { value: string } } = {
  HYBRID: {
    value: 'Interview + Test',
  },
  TEST_BASED: {
    value: 'Test',
  },
  INTERVIEW_BASED: {
    value: 'Interview',
  },
};

class UtilService {
  avatarUrl() {
    const index = Math.floor(Math.random() * AVATAR_URLS.length);
    return AVATAR_URLS[index];
  }

  categoryUrl() {
    const index = Math.floor(Math.random() * CATEGORY_IMAGE_URLS.length);
    return CATEGORY_IMAGE_URLS[index];
  }

  subCategoryUrl() {
    const index = Math.floor(Math.random() * SUB_CATEGORY_IMAGE_URLS.length);
    return SUB_CATEGORY_IMAGE_URLS[index];
  }

  jobRolesUrl() {
    const index = Math.floor(Math.random() * JOB_ROLES_IMAGE_URLS.length);
    return JOB_ROLES_IMAGE_URLS[index];
  }

  backgroundColor() {
    const index = Math.floor(Math.random() * ICON_BACKGROUNDS.length);
    return ICON_BACKGROUNDS[index];
  }

  skillUrl(skill: string) {
    const index = Math.floor(Math.random() * SKILL_IMAGE_URLS.length);
    const item = SKILL_IMAGE_URLS.find((item) => {
      const name = item.split('/').pop()?.split('.')[0] ?? '';
      return skill?.toLowerCase().includes(name?.toLowerCase());
    });
    return item ?? SKILL_IMAGE_URLS[index];
  }
  convertTime(time: number) {
    const formatedTime = moment(time).format('MMMM Do YYYY, H:mm');
    return formatedTime;
  }
  convertDateWithoutTime(time: number) {
    const formatedTime = moment(time).format('MMMM Do YYYY');
    return formatedTime;
  }
  convertTimeToDay(time: number) {
    const formatedTime = moment(time).format('ll');
    return formatedTime;
  }
  convertTo12Hr(time24: string) {
    const timeAMPM = moment(time24, 'HH:mm').format('h:mm');
    return timeAMPM;
  }
  convertToAMPM(time24: string) {
    const timeAMPM = moment(time24, 'HH:mm').format('A');
    return timeAMPM;
  }
  convertSecToHHMMSS(timeInSeconds: number) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const remainingSeconds = timeInSeconds % 60;

    return dayjs()
      .hour(hours)
      .minute(minutes)
      .second(remainingSeconds)
      .format('HH:mm:ss');
  }
}
export function formatDateFromTimestamp(timestamp: number | undefined) {
  if (!timestamp) {
    return 'N/A';
  }
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString(undefined, options);
}

export function getMonthFromDate(dateArray: any) {
  const dateObject = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  const month = dateObject.getMonth();

  const months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date();
    date.setMonth(i);
    return date.toLocaleDateString(undefined, { month: 'long' });
  });
  return months[month];
}

export function getDueDays(
  data:
    | InterviewerUpcomingInterviewsType
    | CandidateInterviewDataType
    | InterviewDataType
) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const specifiedDateObject = new Date(
    data?.interviewDate?.[0],
    data?.interviewDate?.[1] - 1,
    data?.interviewDate?.[2]
  );
  const timeDifference = specifiedDateObject.getTime() - currentDate.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

  return dayDifference;
}

export function cleanupAIText(role: string) {
  const charsToRemove = '*"\'`';
  // Escape special characters in the charsToRemove
  const escapedChars = charsToRemove.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // Create a regular expression from the escaped characters
  const regex = new RegExp(`[${escapedChars}]`, 'g');
  // Replace the special characters with an empty string
  return role.replace(regex, '');
}

export function getTimeZoneShortId(timeZone: string) {
  switch (timeZone) {
    case 'US/Samoa':
      return 'US/SST';
    case 'US/Hawaii':
      return 'US/HST';
    case 'US/Alaska':
      return 'US/AT';
    case 'US/Pacific':
      return 'US/PT';
    case 'US/Mountain':
      return 'US/MT';
    case 'US/Central':
      return 'US/CT';
    case 'US/Eastern':
      return 'US/ET';
    default:
      return timeZone;
  }
}

export const UsStateCities = [
  {
    state: 'Alabama',
    cities: ['Birmingham', 'Montgomery', 'Mobile', 'Huntsville', 'Tuscaloosa'],
  },
  {
    state: 'Alaska',
    cities: ['Anchorage', 'Fairbanks', 'Juneau', 'Sitka', 'Ketchikan'],
  },
  {
    state: 'Arizona',
    cities: ['Phoenix', 'Tucson', 'Mesa', 'Chandler', 'Glendale'],
  },
  {
    state: 'Arkansas',
    cities: [
      'Little Rock',
      'Fort Smith',
      'Fayetteville',
      'Springdale',
      'Jonesboro',
    ],
  },
  {
    state: 'California',
    cities: ['Los Angeles', 'San Diego', 'San Jose', 'San Francisco', 'Fresno'],
  },
  {
    state: 'Colorado',
    cities: [
      'Denver',
      'Colorado Springs',
      'Aurora',
      'Fort Collins',
      'Lakewood',
    ],
  },
  {
    state: 'Connecticut',
    cities: ['Bridgeport', 'New Haven', 'Stamford', 'Hartford', 'Waterbury'],
  },
  {
    state: 'Delaware',
    cities: ['Wilmington', 'Dover', 'Newark', 'Middletown', 'Smyrna'],
  },
  {
    state: 'Florida',
    cities: ['Jacksonville', 'Miami', 'Tampa', 'Orlando', 'St. Petersburg'],
  },
  {
    state: 'Georgia',
    cities: ['Atlanta', 'Augusta', 'Columbus', 'Macon', 'Savannah'],
  },
  {
    state: 'Hawaii',
    cities: ['Honolulu', 'Hilo', 'Kailua', 'Kapolei', 'Kaneohe'],
  },
  {
    state: 'Idaho',
    cities: ['Boise', 'Meridian', 'Nampa', 'Idaho Falls', 'Pocatello'],
  },
  {
    state: 'Illinois',
    cities: ['Chicago', 'Aurora', 'Naperville', 'Joliet', 'Rockford'],
  },
  {
    state: 'Indiana',
    cities: [
      'Indianapolis',
      'Fort Wayne',
      'Evansville',
      'South Bend',
      'Carmel',
    ],
  },
  {
    state: 'Iowa',
    cities: [
      'Des Moines',
      'Cedar Rapids',
      'Davenport',
      'Sioux City',
      'Waterloo',
    ],
  },
  {
    state: 'Kansas',
    cities: ['Wichita', 'Overland Park', 'Kansas City', 'Topeka', 'Olathe'],
  },
  {
    state: 'Kentucky',
    cities: [
      'Louisville',
      'Lexington',
      'Bowling Green',
      'Owensboro',
      'Covington',
    ],
  },
  {
    state: 'Louisiana',
    cities: [
      'New Orleans',
      'Baton Rouge',
      'Shreveport',
      'Lafayette',
      'Lake Charles',
    ],
  },
  {
    state: 'Maine',
    cities: ['Portland', 'Lewiston', 'Bangor', 'South Portland', 'Auburn'],
  },
  {
    state: 'Maryland',
    cities: ['Baltimore', 'Frederick', 'Rockville', 'Gaithersburg', 'Bowie'],
  },
  {
    state: 'Massachusetts',
    cities: ['Boston', 'Worcester', 'Springfield', 'Cambridge', 'Lowell'],
  },
  {
    state: 'Michigan',
    cities: [
      'Detroit',
      'Grand Rapids',
      'Warren',
      'Sterling Heights',
      'Ann Arbor',
    ],
  },
  {
    state: 'Minnesota',
    cities: ['Minneapolis', 'Saint Paul', 'Rochester', 'Duluth', 'Bloomington'],
  },
  {
    state: 'Mississippi',
    cities: ['Jackson', 'Gulfport', 'Southaven', 'Hattiesburg', 'Biloxi'],
  },
  {
    state: 'Missouri',
    cities: [
      'Kansas City',
      'St. Louis',
      'Springfield',
      'Columbia',
      'Independence',
    ],
  },
  {
    state: 'Montana',
    cities: ['Billings', 'Missoula', 'Great Falls', 'Bozeman', 'Butte'],
  },
  {
    state: 'Nebraska',
    cities: ['Omaha', 'Lincoln', 'Bellevue', 'Grand Island', 'Kearney'],
  },
  {
    state: 'Nevada',
    cities: ['Las Vegas', 'Henderson', 'Reno', 'North Las Vegas', 'Sparks'],
  },
  {
    state: 'New Hampshire',
    cities: ['Manchester', 'Nashua', 'Concord', 'Derry', 'Rochester'],
  },
  {
    state: 'New Jersey',
    cities: ['Newark', 'Jersey City', 'Paterson', 'Elizabeth', 'Edison'],
  },
  {
    state: 'New Mexico',
    cities: ['Albuquerque', 'Las Cruces', 'Rio Rancho', 'Santa Fe', 'Roswell'],
  },
  {
    state: 'New York',
    cities: ['New York City', 'Buffalo', 'Rochester', 'Yonkers', 'Syracuse'],
  },
  {
    state: 'North Carolina',
    cities: ['Charlotte', 'Raleigh', 'Greensboro', 'Durham', 'Winston-Salem'],
  },
  {
    state: 'North Dakota',
    cities: ['Fargo', 'Bismarck', 'Grand Forks', 'Minot', 'West Fargo'],
  },
  {
    state: 'Ohio',
    cities: ['Columbus', 'Cleveland', 'Cincinnati', 'Toledo', 'Akron'],
  },
  {
    state: 'Oklahoma',
    cities: ['Oklahoma City', 'Tulsa', 'Norman', 'Broken Arrow', 'Edmond'],
  },
  {
    state: 'Oregon',
    cities: ['Portland', 'Salem', 'Eugene', 'Gresham', 'Hillsboro'],
  },
  {
    state: 'Pennsylvania',
    cities: ['Philadelphia', 'Pittsburgh', 'Allentown', 'Erie', 'Reading'],
  },
  {
    state: 'Rhode Island',
    cities: [
      'Providence',
      'Warwick',
      'Cranston',
      'Pawtucket',
      'East Providence',
    ],
  },
  {
    state: 'South Carolina',
    cities: [
      'Columbia',
      'Charleston',
      'North Charleston',
      'Mount Pleasant',
      'Rock Hill',
    ],
  },
  {
    state: 'South Dakota',
    cities: ['Sioux Falls', 'Rapid City', 'Aberdeen', 'Brookings', 'Watertown'],
  },
  {
    state: 'Tennessee',
    cities: ['Nashville', 'Memphis', 'Knoxville', 'Chattanooga', 'Clarksville'],
  },
  {
    state: 'Texas',
    cities: ['Houston', 'San Antonio', 'Dallas', 'Austin', 'Fort Worth'],
  },
  {
    state: 'Utah',
    cities: [
      'Salt Lake City',
      'West Valley City',
      'Provo',
      'West Jordan',
      'Orem',
    ],
  },
  {
    state: 'Vermont',
    cities: [
      'Burlington',
      'South Burlington',
      'Rutland',
      'Barre',
      'Montpelier',
    ],
  },
  {
    state: 'Virginia',
    cities: [
      'Virginia Beach',
      'Norfolk',
      'Chesapeake',
      'Richmond',
      'Newport News',
    ],
  },
  {
    state: 'Washington',
    cities: ['Seattle', 'Spokane', 'Tacoma', 'Vancouver', 'Bellevue'],
  },
  {
    state: 'West Virginia',
    cities: [
      'Charleston',
      'Huntington',
      'Morgantown',
      'Parkersburg',
      'Wheeling',
    ],
  },
  {
    state: 'Wisconsin',
    cities: ['Milwaukee', 'Madison', 'Green Bay', 'Kenosha', 'Racine'],
  },
  {
    state: 'Wyoming',
    cities: ['Cheyenne', 'Casper', 'Laramie', 'Gillette', 'Rock Springs'],
  },
];

export function padWithZero(val: number) {
  return val.toString().padStart(2, '0');
}
export const utilService = new UtilService();
