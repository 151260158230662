import { Libraries } from '@react-google-maps/api';

export const MapLibraries: Libraries = ['places'];

export const CustomMapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#425763' }, { lightness: -10 }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#1E1E1E' }, { lightness: -20 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#606060' }, { lightness: -10 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#606060' }, { lightness: -20 }, { weight: 0.2 }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#606060' }, { lightness: -10 }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#606060' }, { lightness: -10 }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#2E2C2C' }, { lightness: -20 }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#3D3939' }, { lightness: -20 }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }, { color: '#9E9E9E' }, { lightness: 1 }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 1 }, { color: '#9E9E9E' }, { lightness: -20 }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#4B4545' }, { lightness: -20 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#FF1212' }, { lightness: -80 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#FF1212' }, { lightness: -28 }, { weight: 0.8 }],
  },
];

export const USLatLngBounds: google.maps.LatLngBoundsLiteral = {
  north: 49.3457868, // north lat
  west: -124.7844079, // west long
  east: -66.9513812, // east long
  south: 24.7433195, // south lat
};

export const USCenterLatLng: google.maps.LatLngLiteral = { lat: 28, lng: -94 };

export const DummyUserLocation: google.maps.LatLngLiteral = {
  lat: 37.0902,
  lng: -95.7129,
};

export const CustomLightMapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#87CEEB' }, { lightness: 20 }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#F0F0F0' }, { lightness: 20 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#F0F0F0' }, { lightness: 20 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#D3D3D3' }, { lightness: 20 }, { weight: 0.2 }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#D3D3D3' }, { lightness: 20 }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#D3D3D3' }, { lightness: 20 }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#FFFFFF' }, { lightness: 20 }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#F5F5F5' }, { lightness: 20 }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }, { color: '#FFFFFF' }, { lightness: 1 }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 1 }, { color: '#000000' }, { lightness: 20 }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#F0F0F0' }, { lightness: 20 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#F44336' }, { lightness: -80 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#F44336' }, { lightness: -28 }, { weight: 0.8 }],
  },
];
