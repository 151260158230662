'use client';

import useAlert from '@/src/providers/AlertContextProvider';
import { ApiService } from '@/src/services/api.service';
import { UsStateCities } from '@/src/services/util.service';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { fromAddress, fromLatLng, setKey } from 'react-geocode';
import { useForm } from 'react-hook-form';
import { FaBullseye } from 'react-icons/fa';
import * as yup from 'yup';
import { TextField } from '../../CandidateProfile/fields/TextField';
import { Label } from '../../Label/Label';
import { Select } from '../../Select/Select';
import { USCenterLatLng, USLatLngBounds } from '../Map/MapConfig';
import { useClickAway } from '@/src/hooks/useClickAway';
import { MdOutlineMyLocation } from 'react-icons/md';

export function CubeForm({
  setIsFormModalOpen,
  setIsLocationModalOpen,
  setAddress,
  setPosition,
  setSearchResult,
  setSearchValue,
  userRole,
  setMapView,
}: {
  setIsFormModalOpen: (arg: boolean) => void;
  setIsLocationModalOpen: (arg: boolean) => void;
  setAddress: (arg: { city: string; state: string; country: string }) => void;
  setPosition: (arg: {
    latitude: string | null;
    longitude: string | null;
  }) => void;
  setSearchResult: (
    arg: { label: string; value: string }[] | undefined | null
  ) => void;
  setSearchValue: (arg: string) => void;
  userRole: string;
  setMapView: (arg: {
    address: string;
    location: google.maps.LatLng | undefined;
    bounds: google.maps.LatLngBounds | undefined;
  }) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { showAlert } = useAlert();
  const [selectedCities, setSelectedCities] = useState<any>([]);
  const divRef = useRef<HTMLDivElement>(null);

  const formSchema = yup.object({
    street: yup
      .string()
      .required('Required')
      .matches(/^[^\s][\w\W]*[^\s]$/, 'Provide valid street name'),
    city: yup
      .string()
      .required('Required')
      .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'Provide valid city name'),
    state: yup
      .string()
      .nonNullable()
      .required('Required')
      .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'Provide valid state name'),
    zipcode: yup.string().required('Required'),
  });

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      state: '',
      city: '',
      street: '',
      zipcode: '',
    },
    mode: 'onChange',
  });

  setKey('AIzaSyB9r1OmxCle0vbI2YqblXpj_BcCiApDJrA');

  watch();

  const submitValues = async () => {
    const city = getValues('city');
    const street = getValues('street');
    const state = getValues('state');

    try {
      const { lat, lng } = await getCoordinatesFromAddress(street, city, state);
      const response = await ApiService.requestCubeInstallation({
        latitude: lat?.toFixed(5),
        longitude: lng?.toFixed(5),
        comment: null,
      });
      const data = await response.json();
      if (data.responseCode === 'OK') {
        showAlert({
          type: 'success',
          children: <div className="text-sm">Request send successfully.</div>,
          timeout: 2000,
          position: 'top',
          showIcon: true,
        });
      } else {
        showAlert({
          type: 'warning',
          children: <div className="text-sm">{data?.message}</div>,
          timeout: 2000,
          position: 'top',
          showIcon: true,
        });
      }
      setIsFormModalOpen(false);
      setSearchResult(null);
      setSearchValue('');
      setMapView({
        location: new google.maps.LatLng(USCenterLatLng),
        bounds: new google.maps.LatLngBounds(USLatLngBounds),
        address: '',
      });
    } catch (e) {
      console.log('Error while submitting request cube form', e);
    }
  };

  const setUserLocation = () => {
    setErrorMessage(undefined);
    setIsFormModalOpen(false);
    setIsLocationModalOpen(true);
    setSearchResult(null);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude.toFixed(5).toString();
          const longitude = position.coords.longitude.toFixed(5).toString();
          setPosition({
            latitude,
            longitude,
          });
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error(error);
          setErrorMessage('Could not retrieve location. Please try again.');
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  const getAddressFromCoordinates = async (
    latitude: string,
    longitude: string
  ) => {
    try {
      const response = await fromLatLng(Number(latitude), Number(longitude));
      const results = await response.results[0];
      const { city, state, country } = results.address_components.reduce(
        (
          acc: { city: string; state: string; country: string },
          component: { types: string[]; long_name: string }
        ) => {
          if (component.types.includes('locality'))
            acc.city = component.long_name;
          else if (component.types.includes('administrative_area_level_1'))
            acc.state = component.long_name;
          else if (component.types.includes('country'))
            acc.country = component.long_name;
          return acc;
        }
      );
      setAddress({ city: city, state: state, country: country });
    } catch (error) {
      console.log(error);
    }
  };

  const getCoordinatesFromAddress = async (
    street: string,
    city: string,
    state: string
  ) => {
    const address = `${street} ${city} ${state}`;
    try {
      const response = await fromAddress(address);
      const results = await response?.results[0];
      return results?.geometry?.location;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const stateData = UsStateCities.find(
      (data) => data.state === getValues('state')
    );
    if (stateData) {
      setSelectedCities(stateData.cities);
    } else {
      setSelectedCities([]);
    }
  }, [getValues('state')]);

  useClickAway({ ref: divRef, callback: () => setIsFormModalOpen(false) });

  return (
    <div
      className={clsx(
        userRole === 'client'
          ? 'bg-white'
          : 'bg-dblack border border-neutral-700',
        'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[52.6rem] h-fit rounded-2xl shadow px-10 py-5 mt-10'
      )}
      ref={divRef}
    >
      <div
        className="absolute -top-5 -right-5 cursor-pointer"
        onClick={() => setIsFormModalOpen(false)}
      >
        <svg
          className="w-12 h-12"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="64" height="64" rx="32" fill="#3F51B5" />
          <path
            d="M22 22L41.9865 42"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
          />
          <path
            d="M42 22L22.0135 42"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div>
        <p className="text-indigo-400 text-[32px] font-semibold leading-[38.40px]">
          Request Cube
        </p>
        <p className="text-neutral-400 text-base font-normal  leading-tight mt-4">
          Add request for a new Cube nearby you
        </p>
      </div>
      <div className="w-full justify-center grid grid-flow-col mt-4 items-center gap-7">
        <div
          className={clsx(
            userRole === 'client'
              ? 'bg-white text-indigo-500  border border-indigo-500'
              : 'bg-gray-800 text-white decoration-white',
            'grid grid-flow-col py-2 px-5 rounded-full cursor-pointer select-none gap-2 items-center '
          )}
          onClick={setUserLocation}
        >
          <MdOutlineMyLocation
            className={clsx(
              userRole === 'client' ? 'fill-indigo-500' : 'fill-white ',
              ' w-8 h-8'
            )}
          />
          <span
            className={clsx(
              userRole === 'client' ? 'text-indigo-500' : 'text-white ',
              'text-lg font-normal'
            )}
          >
            Use my current location
          </span>
        </div>
      </div>
      <div className="mt-7 relative px-24">
        <hr className="text-gray-700" />
        <div
          className={clsx(
            userRole === 'client' ? 'bg-white' : 'bg-dblack',
            'absolute -top-[0.7rem] left-1/2  px-4'
          )}
        >
          <span
            className={clsx(
              userRole === 'client' ? 'text-gray-400' : 'text-white',
              'text-sm  font-normal leading-4'
            )}
          >
            or
          </span>
        </div>
      </div>
      <form
        className="h-max select-none text-left"
        onSubmit={handleSubmit(submitValues)}
      >
        <div className="mt-12">
          <div className="flex flex-col flex-1 gap-y-2">
            <TextField
              containerClassName="relative grid grid-rows-auto-10 gap-3 !w-full"
              label="Street address"
              identifier="street"
              register={register}
              valueFn={getValues}
              validations={{
                required: 'Required',
                maxLength: 100,
              }}
              errors={errors}
              inputClass="!pl-2"
            />
            <div className="flex gap-x-6 mt-3">
              <div className="relative grid grid-rows-auto-10">
                <div className="flex flex-col gap-y-4">
                  <Label className="text-neutral-400 text-base font-normal mb-1">
                    State<span className="text-error">*</span>
                  </Label>
                  <Select
                    id="state"
                    value={getValues().state}
                    className={clsx(
                      userRole === 'client'
                        ? '!bg-white text-neutral-700'
                        : `!bg-black text-white  ${
                            !errors.state?.message && 'border-gun-powder'
                          }`,
                      '  !w-[16rem] text-base font-normal rounded-md  !py-3'
                    )}
                    containerClassName="after: content: '' after:text-black after:text-lg after:top-10% after:right-10px after:bg-transparent after:h-0 after:w-0 after:border-r-[8px] after:border-r-transparent after:border-l-[8px] after:border-l-transparent after:border-t-[8px] after:border-denim"
                    errorMsg={errors.state?.message as string}
                    {...register('state')}
                    errorClass="absolute -bottom-6 !text-sm"
                  >
                    <option
                      value=""
                      selected
                      disabled
                      hidden
                      className="text-neutral-400 text-base font-normal"
                    >
                      Select a state
                    </option>
                    {UsStateCities.map((data, index) => (
                      <option value={data.state} key={index}>
                        {data.state}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="relative grid grid-rows-auto-10">
                <div className="flex flex-col gap-y-4">
                  <Label className="text-neutral-400 text-base font-normal mb-1">
                    City<span className="text-error">*</span>
                  </Label>
                  <Select
                    id="city"
                    value={getValues().city}
                    className={clsx(
                      userRole === 'client'
                        ? '!bg-white text-neutral-700'
                        : `!bg-black text-white  ${
                            !errors.state?.message && 'border-gun-powder'
                          }`,
                      ` !w-[16rem] text-base font-normal rounded-md  !py-3 ${
                        !getValues().state && 'cursor-not-allowed'
                      }`
                    )}
                    containerClassName="after: content: '' after:text-black after:text-lg after:top-50% after:right-10px after:bg-transparent after:h-0 after:w-0 after:border-r-[8px] after:border-r-transparent after:border-l-[8px] after:border-l-transparent after:border-t-[8px] after:border-denim"
                    errorMsg={errors.city?.message as string}
                    {...register('city')}
                    disabled={!getValues().state}
                    errorClass="absolute -bottom-6 !text-sm"
                  >
                    <option value="" selected disabled hidden />
                    {selectedCities?.map((data: string, index: number) => {
                      return (
                        <option value={data} key={index}>
                          {data}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="flex flex-col ">
                <TextField
                  containerClassName="relative grid grid-rows-auto-10 gap-y-5"
                  label="Zip"
                  identifier="zipcode"
                  register={register}
                  valueFn={getValues}
                  validations={{
                    required: 'Required',
                    pattern: /^\d+$/,
                    maxLength: 6,
                  }}
                  errors={errors}
                  inputClass="!pl-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-end gap-x-6 mt-2.5 pr-8">
          <p
            className={clsx(
              userRole === 'client' ? 'text-neutral-700' : 'text-white',
              'text-base font-normal  leading-normal cursor-pointer'
            )}
            onClick={() => setIsFormModalOpen(false)}
          >
            Cancel
          </p>
          <button
            className="bg-blue-700 px-9 py-3 text-white text-base font-normal leading-normal rounded-full"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
