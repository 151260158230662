'use client';

import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

type KYCSectionPropsType = {
  isMobile?: boolean;
};

const KYC_SLIDES = [
  {
    id: 0,
    title: 'Candidate ID Verification Takes Off!',
    img: '/images/landing-page/lp-kyc-id.png',
    interval: 2200,
    description: (
      <>
        <div className="lg:hidden">
          <p>Are you tired of collecting applicants&apos; ID documents</p>
          <p className="xs:my-1 lg:my-2">
            through emails and checking their authenticity?
          </p>
        </div>

        <div className="hidden lg:block">
          <p>
            Are you tired of collecting applicants&apos; ID documents through
          </p>
          <p className="xs:my-1 lg:my-2">
            emails and checking their authenticity?
          </p>
        </div>

        <br />
        <p>Well, we&apos;ve automated it for you! Just invite and relax.</p>
        <br />
        <p>Verify IDs through candidate self-service on Interview Cube</p>
        <p className="xs:my-1 lg:my-2 font-semibold">
          Know before hire rather than later!
        </p>
      </>
    ),
    link: '#',
  },
  {
    id: 1,
    title: 'Diverse Range of Official ID Options for Verification',
    img: '/images/landing-page/lp-kyc-id-options.png',
    interval: 4300,
    description: (
      <>
        <p>Explore Interview Cube&apos;s wide range of global identification</p>
        <p className="lg:mt-2">
          options to ensure candidate authenticity swiftly.
        </p>
      </>
    ),
    link: '#',
  },
  {
    id: 2,
    title: 'Facial Recognition for Interview Access',
    img: '/images/landing-page/lp-kyc-face-rec.png',
    interval: 4300,
    description: (
      <>
        <p>
          Make your worry less about candidate authenticity! For interview
          access
        </p>
        <p className="lg:my-2">
          on Interview Cube, candidates are required to submit a facial scan for
        </p>
        <p className="lg:my-2">alignment with official documents.</p>
      </>
    ),
    link: '#',
    animate: true,
  },
];

export default function KYCSection({ isMobile }: KYCSectionPropsType) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [topDistance, setTopDistance] = useState(200);
  const [wrapperHeight, setWrapperHeight] = useState(200);
  const handleScroll = () => {
    setTopDistance(
      (wrapperRef?.current?.getBoundingClientRect()?.top || 200) - 70
    ); // Remove navbar height
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const updateSlide = function () {
      setCurrentSlideIndex((index) => {
        let newIndex = index + 1;
        if (newIndex >= KYC_SLIDES.length) {
          newIndex = 0;
        }

        clearInterval(interval);
        interval = setInterval(updateSlide, KYC_SLIDES[newIndex]?.interval);

        return newIndex;
      });
    };

    let interval = setInterval(updateSlide, KYC_SLIDES[0]?.interval);

    return () => clearInterval(interval);
  }, []);

  const currentSlide = KYC_SLIDES[currentSlideIndex];

  return (
    <div
      className="container items-center xs:py-8  lg:py-[3rem] xs:px-0"
      ref={wrapperRef}
      style={{
        transform: !isMobile
          ? `translateX(${
              topDistance <= 0
                ? 0
                : topDistance > wrapperHeight
                ? '200%'
                : `${(topDistance / wrapperHeight) * 100}%`
            })`
          : 'none',
      }}
    >
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="flex-1 flex flex-col text-center lg:text-left lg:justify-center pb-[3rem] lg:pb-0 lg:pl-[1rem] relative">
          <h2 className="text-black xs:text-[1.25rem] lg:text-[38px] xs:font-semibold lg:font-medium mb-[0.25rem] lg:mb-6 lg:absolute lg:top-20 xs:leading-10 lg:leading-0">
            KYC (Know-Your-Candidate)
          </h2>
          <div className="lg:absolute top-40">
            <p className="text-royal-blue xs:text-base lg:text-2xl font-normal lg:font-medium lg:text-[1.5rem] pb-[0.86rem] lg:pb-3  ">
              {currentSlide?.title}
            </p>
            <div className="text-[#6c6c6c] xs:text-xs lg:text-[1.125rem] xs:mb-2 lg:mb-6 lg:mt-3 break-words">
              {currentSlide?.description}
            </div>
            <Link
              href={`features?kycId=${currentSlide?.id + 1}`}
              className="text-royal-blue xs:text-xs lg:text-[1.125rem] pt-[0.25rem] lg:pt-3 xs:font-medium lg:font-normal"
            >
              Know More
            </Link>
          </div>
        </div>
        <div className="flex-1 text-center relative flex flex-col items-center justify-center">
          <video
            preload="none"
            autoPlay
            loop
            muted
            disablePictureInPicture
            className="relative z-10 outline outline-[#f4f4f4] outline-4 outline-offset-[-4px]"
          >
            <source
              src="/images/landing-page/kyc-animation.webm"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="flex w-[100%] lg:w-[70%] gap-[0.1rem] lg:gap-[0.4rem] mt-4">
            {KYC_SLIDES.map((slide, index) => (
              <span
                key={slide?.id}
                className={`flex flex-1 h-[0.2rem] lg:h-[0.4rem] rounded-lg transition-colors ${
                  currentSlideIndex === index ? 'bg-[#3D9AFF]' : 'bg-[#D9D9D9]'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
