'use client';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo, useState } from 'react';
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import NavBarCta from './NavBarCta';
import NavBarLinks from './NavBarLinks';
import styles from './styles.module.css';

export default function NavBar({
  disableAnimation = true,
  isDarkMode = false,
}: {
  disableAnimation?: boolean;
  isDarkMode?: boolean;
}) {
  const pathname = usePathname();

  const [open, setOpen] = useState(false);

  const isHomePage = useMemo(() => !pathname || pathname === '/', [pathname]);

  const animate = useMemo(
    () => !disableAnimation && isHomePage,
    [disableAnimation, isHomePage]
  );

  return (
    <>
      <div
        className={clsx(
          animate
            ? styles.navbar
            : isDarkMode
            ? 'bg-[#1B213C] shadow-custom-dark'
            : 'bg-zinc-100 shadow-custom',
          animate ? 'z-50' : 'z-[51]',
          'top-0 fixed z-50 w-full h-16 flex items-center '
        )}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 flex-1">
          <div className="flex justify-between items-center md:justify-start lg:justify-between md:space-x-10 relative">
            <Link href="/">
              <div className="relative h-[36px] w-[81px]">
                <Image
                  src="/images/ic-logo.png"
                  height={36}
                  width={81}
                  style={{ minWidth: '81px' }}
                  alt="Interview Cube Logo"
                  className={
                    animate
                      ? styles.logo_dark
                      : `${
                          isDarkMode ? 'hidden' : 'block'
                        } absolute top-0 left-0`
                  }
                />
                <Image
                  src="/images/ic-logo-light.png"
                  height={36}
                  width={81}
                  style={{ minWidth: '81px' }}
                  alt="Interview Cube Logo"
                  className={
                    animate
                      ? styles.logo_light
                      : `${
                          isDarkMode ? 'block' : 'hidden'
                        } absolute top-0 left-0`
                  }
                />
              </div>
            </Link>
            <button
              type="button"
              className="md:hidden cursor-pointer bg-blue-700 rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setOpen(!open)}
            >
              <span className="sr-only">Open menu</span>
              <IoIosMenu size={26} className="text-white" />
            </button>
            <NavBarLinks
              className="hidden md:flex md:flex-1 md:justify-between md:max-w-[500px] mx-auto relative"
              isDarkMode={isDarkMode}
              animate={animate}
            />
            <NavBarCta
              className="gap-3 hidden md:flex items-center justify-end relative "
              isDarkMode={isDarkMode}
              animate={animate}
            />
          </div>
        </div>
        {/*--------------MOBILE VIEW------------------*/}
        <div
          className={
            open
              ? 'opacity-100 scale-100 ease-out duration-200 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
              : 'opacity-0 scale-0 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden pointer-events-none'
          }
        >
          <div
            className={clsx(
              isDarkMode ? 'bg-[#1B213C]' : 'bg-white',
              'rounded-lg shadow-lg ring-1 ring-black ring-opacity-5  divide-y-2 divide-gray-50'
            )}
          >
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Image
                    src="/images/ic-logo.png"
                    height={36}
                    width={81}
                    alt="Interview Cube Logo"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className={clsx(
                      isDarkMode ? '' : 'bg-white',
                      'rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                    )}
                    onClick={() => setOpen(!open)}
                  >
                    <span className="sr-only">Close menu</span>
                    <IoIosClose size={30} />
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <NavBarLinks
                  className="grid gap-y-6 pb-6"
                  isDarkMode={isDarkMode}
                  animate={false}
                  setOpen={setOpen}
                  open={open}
                />
                <NavBarCta
                  className="flex gap-4"
                  isDarkMode={isDarkMode}
                  animate={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {animate && <div className="w-full h-14 md:h-[4.375rem]" />}
    </>
  );
}
