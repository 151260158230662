'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

type SmartFeatureSectionProps = {
  isMobile?: boolean;
};

export default function SmartFeaturesSection({
  isMobile,
}: SmartFeatureSectionProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [topDistance, setTopDistance] = useState(200);
  const [wrapperHeight, setWrapperHeight] = useState(200);
  const handleScroll = () => {
    setTopDistance(
      (wrapperRef?.current?.getBoundingClientRect()?.top || 200) - 70
    ); // Remove navbar height
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const percent = (topDistance / wrapperHeight) * 100;

  return (
    <div
      className="container items-center justify-center xs:py-[1rem] lg:py-[3rem] mb-5"
      ref={wrapperRef}
    >
      <div className="flex flex-col text-center items-center">
        <h3 className="text-[#3A3A3A] font-bold xs:text-xl  lg:text-4xl pb-[0.25rem] lg:pb-[1rem]">
          Smart Features for Happy Recruitment
        </h3>
        {isMobile ? (
          <div className="xs:text-neutral-500 lg:text-zinc-600 xs:text-sm lg:text-base lg:pb-[2.5rem] max-w-[80rem] font-normal">
            We understand the global challenges in the hiring process for both
            job seekers and HR teams. That&apos;s why our tech-savvy features
            aim to make finding the right employee easier for you, standing out
            in the entire recruiting spectrum at your fingertip.
          </div>
        ) : (
          <div className="xs:text-neutral-500 lg:text-zinc-600 xs:text-sm lg:text-base lg:pb-[2.5rem] max-w-[80rem] font-normal">
            We understand the global challenges in the hiring process for both
            job seekers and HR teams. That&apos;s why our tech-savvy
            <div>
              features aim to make finding the right employee easier for you,
              standing out in the entire recruiting spectrum at your fingertip.
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col lg:flex-row max-w-[65rem] mx-auto">
        {CARDS.map((col, index) => (
          <div
            key={index}
            className="flex flex-col items-stretch mx-[1rem]"
            style={{
              transform: !isMobile
                ? topDistance <= 0
                  ? 'translate(0, 0)'
                  : topDistance > wrapperHeight
                  ? index === 0
                    ? 'translate(-50%, 50%)'
                    : 'translate(50%, 50%)'
                  : index === 0
                  ? `translate(-${percent}%, ${percent}%)`
                  : `translate(${percent}%, ${percent}%)`
                : 'none',
            }}
          >
            {col.map((card, index) => (
              <div
                key={card?.id}
                className="flex flex-1 flex-col bg-white rounded-lg shadow-md p-[1.5rem] my-[0.75rem] lg:my-[1rem] min-h-0 lg:min-h-[18.25rem]"
              >
                <div className="flex flex-1 flex-row lg:flex-col items-start lg:items-stretch">
                  <Image
                    src={card?.img || ''}
                    alt={'Smart Feature Image' + card?.id}
                    height={48}
                    width={48}
                  />
                  <div className="flex flex-col items-stretch pl-[0.75rem] lg:pl-0">
                    <h1 className="text-[#3A3A3A] lg:pt-[1.5rem] text-[1rem] lg:text-[1.5rem] font-semibold">
                      {card?.title}
                    </h1>
                    <p className="text-[#676C72] text-[0.75rem] lg:text-[1rem]">
                      {card?.description}
                    </p>
                  </div>
                </div>
                <Link
                  href={card?.link as string}
                  className="text-blue-700 font-medium text-[0.75rem] xs:text-[0.87rem] lg:text-base pt-[0.25rem] lg:pt-3 block"
                >
                  Know More
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

const CARDS = [
  [
    {
      id: 0,
      title: 'Feedback Collaborator',
      img: '/images/landing-page/lp-smart-feedback.svg',
      description:
        'Instant Learnings from Realtime Feedback on Interview Performances',
      link: '/features#feedback',
    },
    ,
    {
      id: 2,
      title: 'Integrated Video Platform',
      img: '/images/landing-page/lp-smart-video.svg',
      description: 'Essential Video Recording Protocol in Interviews',
      link: '/features#integratedvideo',
    },
  ],
  [
    {
      id: 1,
      title: 'Interview Slot Assistant',
      img: '/images/landing-page/lp-smart-slot.svg',
      description:
        'Automated and Simplified Scheduling with Real-time Data Power',
      link: '/features#interviewslot',
    },
    {
      id: 3,
      title: 'Prescreen Profiler',
      img: '/images/landing-page/lp-smart-prescreen.svg',
      description: 'Prescreen Check for Skills Alignment',
      link: '/features#prescreen',
    },
  ],
];
