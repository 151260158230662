'use client';

import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';

const STEPS = [
  {
    id: 1,
    title: (
      <>
        <span className="text-primary">Publish Job</span> Openings and Forward
        Invitations
      </>
    ),
    desc: 'Post multiple job openings with tailored job descriptions (JD) swiftly. Let our AI Assistant quickly generate precise JDs within a few secs! Simplify interview scheduling at mutually convenient times and send invites to candidates hassle-free.',
    img: {
      src: '/images/landing-page/lp-hr-publish.png',
      alt: 'Publish job openings and forward invitations',
    },
  },
  {
    id: 2,
    title: (
      <>
        <span className="text-primary">Accepted Invites</span>: Your Candidates
        Schedule a <span className="text-primary">Nearby Cube</span>
      </>
    ),
    desc: 'Considering streamlining your interview logistics? With our platform, candidates easily book flexible time slots nearby to their places while addressing concerns like time constraints, expenses, personal issues, or technical challenges, making it easy for candidates. We prioritize recognizing local talent proficiency and fostering a positive candidate experience.',
    img: {
      src: '/images/landing-page/lp-hr-invite.png',
      alt: 'Candidates accepting invites and scheduling a nearby cube',
    },
  },
  {
    id: 3,
    title: (
      <>
        <span className="text-primary">Engage</span> in{' '}
        <span className="text-primary">Interviews</span> and Make Smart Hire
      </>
    ),
    desc: `Interview Cube provides end-to-end solutions that instill confidence in hiring decisions. Our platform offers easy-to-follow steps, fostering engaging interviews and insightful discussions between interviewers and interviewees. From skill assessments to finding the perfect match, isn't it time for a comprehensive recruiting experience?`,
    img: {
      src: '/images/landing-page/lp-hr-engage.png',
      alt: 'Engage in interviews and make smart hiring decisions',
    },
  },
];

type HonestInterviewSectionPropsTypes = {
  isMobile?: boolean;
};

export default function HonestInterviewsSection({
  isMobile,
}: HonestInterviewSectionPropsTypes) {
  const [currentSlideNb, setCurrentSlideNb] = useState(1);

  useEffect(() => {
    const interval = setInterval(function () {
      setCurrentSlideNb((index) => {
        let newIndex = index + 1;
        if (newIndex > STEPS.length) {
          newIndex = 1;
        }

        return newIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container items-center justify-center pt-[2rem] lg:pt-[3rem]">
      <div className="flex flex-col text-center items-center">
        <h3 className="text-[#1E1E1E]  font-semibold lg:font-bold text-xl lg:text-[2rem] pb-[0.25rem] lg:mb-[2rem]">
          Honest interviews make a well bonded team and thus great companies!
        </h3>
        <p className="text-neutral-500 lg:text-[#676E72] text-[0.75rem] xs:text-xs font-normal lg:text-xl pb-[2.5rem] max-w-[50rem]">
          Employer-Employee relationship is very important for both. Doesn’t it
          {!isMobile && <br />}
          <>
            deserve a serious date at our secure Cubes for a healthier
            discussion?
          </>
        </p>
      </div>
      <div className="flex items-center justify-evenly w-full max-w-[48rem] mt-2 mx-auto">
        {STEPS.map((item) => (
          <Fragment key={item?.id}>
            <div
              className="flex rounded-full h-[2.5rem] w-[2.5rem] text-white items-center justify-center"
              style={{
                backgroundColor:
                  item?.id <= currentSlideNb ? '#274CE0' : '#6E6E6E',
              }}
            >
              {item?.id}
            </div>
            {item?.id < STEPS.length && (
              <div className="flex-1">
                <hr
                  className="border border-dashed border-primary flex-1 transition-all duration-500"
                  style={{
                    maxWidth: item?.id < currentSlideNb ? '100%' : '0px',
                  }}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <div className="flex items-stretch mt-8 relative overflow-hidden h-[500px] xl:h-[450px]">
        <div
          className={`mt-[2rem] lg:mt-[5rem] flex flex-1 absolute top-0 left-0 bottom-0 transition-transform duration-500`}
          style={{
            width: `${STEPS.length * 100}%`,
            transform: `translateX(-${
              (currentSlideNb - 1) * (100 / STEPS.length)
            }%)`,
          }}
        >
          {STEPS.map((item) => (
            <div
              key={item?.id}
              className="flex flex-col lg:flex-row items-stretch w-[100%]"
            >
              <div className="flex flex-col items-stretch flex-1 text-center lg:text-left">
                <h2 className="text-[1.25rem] lg:text-[2.25rem] text-[#3A3A3A] font-bold pb-[0.5rem] lg:pb-[1rem]">
                  {item?.title}
                </h2>
                <p className="text-sm text-neutral-500 lg:text-zinc-800 lg:text-xl  font-normal flex-1">
                  {item?.desc}
                </p>
                <Link
                  href="journey"
                  className="py-[0.5rem] px-[1rem] lg:py-3 lg:px-5 rounded-full bg-blue-700 text-white w-fit my-2 lg:my-0 mx-auto lg:mx-0"
                >
                  Know more
                </Link>
              </div>
              <div className="flex flex-1 justify-end">
                <Image
                  src={item?.img.src}
                  alt={item?.img.alt || `Image of ${item?.title}`}
                  className="h-full w-auto"
                  width={530}
                  height={306}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
