import './loader.scss';

const LOADER_SIZES: { [key: string]: number } = {
  sm: 40,
  md: 60,
  lg: 90,
};

export const Loader = ({ size = 'md' }: { size?: 'sm' | 'md' | 'lg' }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 239 379"
        className="loader"
        height={`${LOADER_SIZES[size]}`}
      >
        <rect
          width="185"
          height="325"
          x="27"
          y="27"
          rx="20"
          ry="20"
          className="bg"
        />
        <rect
          width="185"
          height="325"
          x="27"
          y="27"
          rx="20"
          ry="20"
          className="bg ani"
        />
      </svg>
    </div>
  );
};
