import useAlert from '@/src/providers/AlertContextProvider';
import { ApiService } from '@/src/services/api.service';
import clsx from 'clsx';
import { HiLocationMarker } from 'react-icons/hi';
import { USCenterLatLng, USLatLngBounds } from '../Map/MapConfig';
export function LocationModal({
  setIsLocationModalOpen,
  address,
  position,
  setSearchResult,
  setSearchValue,
  setIsFormModalOpen,
  userRole,
  setMapView,
}: {
  setIsFormModalOpen: (arg: boolean) => void;
  setIsLocationModalOpen: (arg: boolean) => void;
  address: { city: string; state: string; country: string };
  position: { latitude: string | null; longitude: string | null };
  setSearchResult: (
    arg: { label: string; value: string }[] | undefined | null
  ) => void;
  setSearchValue: (arg: string) => void;
  userRole: string;
  setMapView: (arg: {
    address: string;
    location: google.maps.LatLng | undefined;
    bounds: google.maps.LatLngBounds | undefined;
  }) => void;
}) {
  const { showAlert } = useAlert();

  const requestCubeHandler = async () => {
    const response = await ApiService.requestCubeInstallation({
      ...position,
      comment: null,
    });
    const data = await response.json();
    if (data.responseCode === 'OK') {
      setIsLocationModalOpen(false);
      setSearchResult(null);
      setSearchValue('');
      setMapView({
        location: new google.maps.LatLng(USCenterLatLng),
        bounds: new google.maps.LatLngBounds(USLatLngBounds),
        address: '',
      });
      showAlert({
        type: 'success',
        children: <div className="text-sm">Request send successfully.</div>,
        timeout: 2000,
        position: 'top',
        showIcon: true,
      });
    }
  };

  return (
    <div
      className={clsx(
        userRole === 'client' ? 'bg-white' : 'bg-dblack',
        'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55.5rem] bg-gray-950 rounded-3xl px-10 py-2 flex items-center justify-between'
      )}
    >
      <div className="flex flex-col">
        <p
          className={clsx(
            userRole === 'client' ? 'text-indigo-500' : 'text-white',
            ' text-2xl font-medium  leading-[43.20px] flex gap-x-2 items-center'
          )}
        >
          <span className="text-red-500">
            <HiLocationMarker />
          </span>{' '}
          {address.city}
        </p>
        <div
          className={clsx(
            userRole === 'client' ? 'text-neutral-500' : 'text-neutral-400',
            '  text-base font-normal leading-snug mt-1'
          )}
        >
          {address.city} {address.state} {address.country}
        </div>
      </div>
      <div className="flex gap-x-8 items-center">
        <p
          className={clsx(
            userRole === 'client' ? 'text-neutral-700' : 'text-white',
            ' text-base font-normal  leading-normal cursor-pointer'
          )}
          onClick={() => {
            setIsLocationModalOpen(false);
            setIsFormModalOpen(true);
          }}
        >
          Cancel
        </p>
        <button
          className="bg-blue-700 px-9 py-3 text-white text-base font-normal leading-normal rounded-full"
          onClick={requestCubeHandler}
        >
          Request Cube
        </button>
      </div>
    </div>
  );
}
