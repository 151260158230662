'use client';

import { useEffect, useState } from 'react';
import { CubesMap } from '@/src/components/CandidateCubeBooking/Map/CubesMap';
import { CubeDetails } from '@/src/services/types';
import { ApiService } from '@/src/services/api.service';
import {
  DummyUserLocation,
  MapLibraries,
  USLatLngBounds,
} from '@/src/components/CandidateCubeBooking/Map/MapConfig';
import { useJsApiLoader } from '@react-google-maps/api';
import { CubeForm } from '@/src/components/CandidateCubeBooking/RequestCube/CubeForm';
import { LocationModal } from '@/src/components/CandidateCubeBooking/RequestCube/LocationModal';
import clsx from 'clsx';
import { FaSearch } from 'react-icons/fa';
import { Loader } from '@/src/components/Loader';
import { useDebounce } from '@/src/hooks/useDebounce';
import { Input } from '@/src/components/Input/Input';
import { MdOutlineMyLocation } from 'react-icons/md';

type MapSectionType = {
  handleSelectedCube: (val: any) => void;
  selectedCube: any;
  searchText: string;
  handleSearchText: (val: string) => void;
  searchCube: (e: any) => void;
  mapRef: any;
  setSearchText: (arg: string) => void;
};

export default function MapSection({
  mapRef,
  searchText,
  setSearchText,
}: MapSectionType) {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB9r1OmxCle0vbI2YqblXpj_BcCiApDJrA',
    libraries: MapLibraries,
  });

  const [mapView, setMapView] = useState<{
    address: string;
    location: google.maps.LatLng | undefined;
    bounds: google.maps.LatLngBounds | undefined;
  }>({ location: undefined, bounds: undefined, address: '' });
  const [showSearch, setShowSearch] = useState<true | false>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [selectedCube, setSelectedCube] = useState<CubeDetails>();
  const [searchResult, setSearchResult] = useState<
    { label: string; value: string }[] | undefined | null
  >();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [searchLocation, setSearchLocation] = useState<string | undefined>('');
  const [allCubes, setAllCubes] = useState<CubeDetails[] | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const debounceValue = useDebounce(searchText || searchValue, 1000);
  const [loadedCubes, setLoadedCubes] = useState<true | false>(false);
  const [address, setAddress] = useState({
    city: '',
    state: '',
    country: '',
  });
  const [position, setPosition] = useState<{
    latitude: string | null;
    longitude: string | null;
  }>({
    latitude: null,
    longitude: null,
  });
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    fetchTestCenters();
  }, []);

  const fetchTestCenters = async () => {
    try {
      const response = await ApiService.fetchTestCenters();
      const resData = await response.json();
      if (resData.responseCode === 'OK') {
        const { data } = resData;
        const { activeTestCentres } = data;
        const allCubeDetails = activeTestCentres.map((t: any) => {
          const cubeDetails: CubeDetails = {
            ...t,
            latitude: Number.parseFloat(t.latitude),
            longitude: Number.parseFloat(t.longitude),
          };
          return cubeDetails;
        });
        setAllCubes(allCubeDetails);
        setLoadedCubes(true);
      }
    } catch (error) {
      console.error('Error fetching cubes', error);
    }
  };

  const setUserLocation = () => {
    setErrorMessage(undefined);
    setSearchValue('');
    setShowSearch(false);
    setSearchResult(null);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLatLng = new google.maps.LatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          const currentLatitude = position.coords.latitude;
          const currentLongitude = position.coords.longitude;
          const USBounds = new google.maps.LatLngBounds(USLatLngBounds);
          if (USBounds.contains(newLatLng)) {
            if (
              allCubes?.every(
                (data) =>
                  data.latitude !== currentLatitude &&
                  data.longitude !== currentLongitude
              )
            ) {
              setSearchResult(undefined);
              setSearchLocation('dummy');
            } else {
              setMapView({
                address: 'Current Location',
                location: newLatLng,
                bounds: undefined,
              });
            }
          } else {
            setSearchResult(undefined);
            setSearchLocation('dummy');
            const dummyLocation = new google.maps.LatLng(DummyUserLocation);
            setMapView({
              address: 'Engineering Square',
              location: dummyLocation,
              bounds: undefined,
            });
          }
        },
        () => {
          setErrorMessage(
            `Couldn't get your location. Use the search instead.`
          );
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  if (loadError) {
    return (
      <div className="w-full h-full grid place-content-center bg-dmain">
        <p className="text-base">
          Couldn&apos;t load the maps. Something went wrong
        </p>
      </div>
    );
  }

  const filterCubes = (cubes: CubeDetails[], searchTerm: string) => {
    if (!searchTerm) return cubes;
    const searchLower = searchTerm.toLowerCase();
    return cubes.filter((cube) => {
      return (
        cube.city?.toLowerCase().includes(searchLower) ||
        cube.state?.toLowerCase().includes(searchLower) ||
        cube.name?.toLowerCase().includes(searchLower) ||
        cube.address?.toLowerCase().includes(searchLower) ||
        cube.zipcode?.toString().includes(searchTerm)
      );
    });
  };

  const filteredCubes = filterCubes(allCubes || [], debounceValue || '');

  const noSearchResults =
    searchResult === undefined &&
    searchLocation !== '' &&
    isLoaded &&
    loadedCubes;

  const noFilteredCubes = isLoaded && loadedCubes && filteredCubes.length === 0;
  const showNoCubesMessage = noSearchResults || noFilteredCubes;

  console.log(debounceValue, 'debounceValue');
  return (
    <div
      className="container items-center justify-center py-[2rem] lg:py-[3rem]"
      ref={mapRef}
    >
      <div className="flex flex-col text-center items-center">
        <h3 className="text-[#1E1E1E] font-bold text-[1.25rem] lg:text-[2rem] pb-[0.25rem] lg:pb-[1rem]">
          Boosting Connectivity: Harnessing the Power of Cubes in Our Expanding
          Network
        </h3>
        <div className="text-neutral-500  text-[0.75rem] lg:text-[1.25rem] pb-[2.5rem] max-w-[70rem]">
          We offer numerous cubes to book your slot for an engaging interview or
          a test in the following areas.{' '}
          <p>Find your nearest cube to tackle your employment obstacles.</p>
        </div>

        <div className="relative grid grid-rows-auto-fr w-full bg-white rounded-md shadow p-8">
          <div className="w-full bg-white grid grid-flow-col items-center content-center text-white fill-white place-content-center">
            <div className="grid grid-flow-row lg:grid-flow-col justify-start items-center gap-7">
              <button
                className={clsx(
                  isFormModalOpen ? 'cursor-not-allowed' : 'cursor-pointer',
                  'grid grid-flow-col py-1 px-3 rounded-full  select-none gap-2 bg-white items-center text-white decoration-white border border-royal-blue'
                )}
                disabled={isFormModalOpen}
                onClick={setUserLocation}
              >
                <MdOutlineMyLocation className="text-royal-blue w-8 h-8" />
                <span className="text-sm lg:text-base text-royal-blue leading-4 font-base">
                  Use My Current Location
                </span>
              </button>
              <label htmlFor="location-search" className="sr-only">
                Search by location
              </label>
              <Input
                id="location-search"
                type="text"
                className={clsx(
                  'border-royal-blue',
                  'bg-white outline-denim rounded-full border text-black-olive !text-sm lg:!text-base placeholder:text-royal-blue placeholder:text-justify !h-10 !pl-[2.5rem]'
                )}
                autoComplete="off"
                placeholder="Search"
                value={searchText || searchValue}
                onChange={(e) => {
                  const value = e?.currentTarget?.value;
                  setSearchValue(value);
                  setSearchText(value);
                }}
                icon={<FaSearch className="text-royal-blue w-5 h-6" />}
                iconClass="absolute left-3 top-2.5"
              />
            </div>
          </div>
          <div className="min-h-[25rem] h-[50vh] lg:h-[75vh] w-full relative mt-6">
            {isLoaded && loadedCubes ? (
              <CubesMap
                controlledAddress={mapView.address}
                controlledCenter={mapView.location}
                controlledBounds={mapView.bounds}
                allCubes={filteredCubes || []}
                selectCube={(c: CubeDetails) => {
                  setSelectedCube(c);
                }}
                setError={(message: string) => setErrorMessage(message)}
                onMapClick={() => {
                  setShowSearch(false);
                  setSearchResult(null);
                }}
                setSearchResult={setSearchResult}
                isDarkTheme={false}
              />
            ) : (
              <div className="grid h-full place-content-center text-base">
                <Loader />
              </div>
            )}
            {showNoCubesMessage && (
              <div className="w-full max-w-[45rem] h-20 bg-white rounded-full px-6 py-4 flex items-center justify-between absolute right-0 left-0 m-auto top-20">
                <p className="text-neutral-700 text-xl font-normal leading-normal">
                  {debounceValue
                    ? `There are no available Cubes near ${debounceValue}`
                    : 'There are no available Cubes nearby you'}
                </p>
                <button
                  className="bg-blue-700 px-8 py-3 rounded-[40px] text-zinc-100 text-base font-normal leading-normal"
                  onClick={() => {
                    setIsFormModalOpen(true);
                    setShowSearch(false);
                  }}
                >
                  Request Cube
                </button>
              </div>
            )}
          </div>

          {isFormModalOpen && (
            <CubeForm
              setIsFormModalOpen={setIsFormModalOpen}
              setIsLocationModalOpen={setIsLocationModalOpen}
              setAddress={setAddress}
              setPosition={setPosition}
              setSearchResult={setSearchResult}
              setSearchValue={setSearchValue}
              userRole="client"
              setMapView={setMapView}
            />
          )}
          {isLocationModalOpen && (
            <LocationModal
              setIsFormModalOpen={setIsFormModalOpen}
              setIsLocationModalOpen={setIsLocationModalOpen}
              address={address}
              position={position}
              setSearchResult={setSearchResult}
              setSearchValue={setSearchValue}
              userRole="client"
              setMapView={setMapView}
            />
          )}
        </div>
      </div>
    </div>
  );
}
