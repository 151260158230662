import { CubeDetails } from '@/src/services/types';
import { InfoBox, MarkerF } from '@react-google-maps/api';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

const MarkerShapeConfig: google.maps.MarkerShape = {
  type: 'rect',
  coords: [0, 0, 100, 100],
};

function CubeMarker({
  map,
  zoomLevel,
  isSelected,
  cube,
  selectCube,
  isDarkTheme = false,
}: {
  map: google.maps.Map | undefined;
  zoomLevel: number;
  isSelected: boolean;
  cube: CubeDetails;
  selectCube: CallableFunction;
  isDarkTheme?: boolean;
}) {
  const [position, setPosition] = useState<google.maps.LatLng | undefined>();
  const [infoVisible, setInfoVisible] = useState<true | false>(false);

  useEffect(() => {
    setPosition(
      new google.maps.LatLng({
        lat: cube.latitude,
        lng: cube.longitude,
      })
    );
  }, [cube]);

  useEffect(() => {
    setInfoVisible(false);
  }, [isSelected]);

  const handleClick = (e: google.maps.MapMouseEvent) => {
    if (!map || !position) return;
    if (!e.latLng) return;
    map.setCenter(position);
    selectCube(cube);
    e.stop();
  };

  const onMousever = (e: google.maps.MapMouseEvent) => {
    setInfoVisible(true);
  };

  const onMouseOut = (e: google.maps.MapMouseEvent) => {
    setInfoVisible(false);
  };

  if (!position) return null;

  const infoOptions = {
    position: position,
    pixelOffset: new google.maps.Size(-100, -280, 'px', 'px'),
    boxClass: 'w-64 h-34',
    closeBoxMargin: '-20px',
    maxWidth: 256,
    visible: infoVisible,
  };

  return (
    <>
      {/* Smaller markers, when zoom level is low. Hidden when zoomed in. */}
      <MarkerF
        key={`SMALL_MARKER_${cube.id}`}
        position={position}
        onClick={handleClick}
        icon={
          !isDarkTheme
            ? '/images/smallcubemarker.svg'
            : '/images/darkcubemarker.svg'
        }
        shape={MarkerShapeConfig}
        visible={zoomLevel < 10 && !isSelected}
        onMouseOver={onMousever}
        onMouseOut={onMouseOut}
      ></MarkerF>
      <MarkerF
        key={`SMALL_MARKER_SELECTED_${cube.id}`}
        position={position}
        onClick={handleClick}
        icon={
          !isDarkTheme
            ? '/images/cubemarker.svg'
            : '/images/selecteddarkcubemarker.svg'
        }
        shape={MarkerShapeConfig}
        visible={zoomLevel < 10 && isSelected}
        onMouseOver={onMousever}
        onMouseOut={onMouseOut}
      ></MarkerF>
      {/* Bigger markers, when zoom level is high. Hidden when zoomed out. */}
      <MarkerF
        key={`BIG_MARKER_${cube.id}`}
        position={position}
        onClick={handleClick}
        label={{
          text: cube.name,
          color: '#375FFF',
          className: 'absolute-side-center',
          fontWeight: 'bold',
        }}
        visible={zoomLevel >= 10 && !isSelected}
        onMouseOver={onMousever}
        onMouseOut={onMouseOut}
        icon={
          !isDarkTheme
            ? '/images/smallcubemarker.svg'
            : '/images/darkcubemarker.svg'
        }
        shape={MarkerShapeConfig}
      >
        <InfoBox key={`INFO_BOX_${cube.id}`} options={infoOptions}>
          <CubeInfo cube={cube} />
        </InfoBox>
      </MarkerF>
      <MarkerF
        key={`BIG_MARKER_SELECTED_${cube.id}`}
        position={position}
        onClick={handleClick}
        label={{
          text: cube.name,
          color: '#FFFFFF',
          className: 'absolute-side-center',
          fontWeight: 'bold',
        }}
        visible={zoomLevel >= 10 && isSelected}
        onMouseOver={onMousever}
        onMouseOut={onMouseOut}
        icon={
          !isDarkTheme
            ? '/images/cubemarker.svg'
            : '/images/selecteddarkcubemarker.svg'
        }
        shape={MarkerShapeConfig}
      >
        <InfoBox key={`INFO_BOX_SELECTED_${cube.id}`} options={infoOptions}>
          <CubeInfo cube={cube} isDarkTheme={isDarkTheme} />
        </InfoBox>
      </MarkerF>
    </>
  );
}

function CubeInfo({
  cube,
  isDarkTheme = false,
}: {
  cube: CubeDetails;
  isDarkTheme?: boolean;
}) {
  return (
    <div
      className={clsx(
        !isDarkTheme
          ? 'bg-white text-neutral-700 border border-black'
          : 'bg-dblack text-white',
        'grid grid-flow-row rounded-lg overflow-hidden w-full'
      )}
    >
      <div className="h-28 w-full">
        <Image
          className="object-cover w-full h-28"
          src={cube.imageURL || '/images/cubeDefaultMarker.jpg'}
          alt="Image Not Provided"
          height={112}
          width={112}
        />
      </div>
      <div className="h-auto w-full flex flex-col justify-start p-3 gap-y-1 font-medium">
        <div className="w-full flex items-start justify-start">
          <span
            className={clsx(
              !isDarkTheme ? 'text-gray-400' : 'text-gray-300',
              'w-2/5'
            )}
          >
            ID :{' '}
          </span>
          <span
            className={clsx(
              !isDarkTheme ? 'text-black' : 'text-white',
              'w-3/5 '
            )}
          >
            {cube.id}
          </span>
        </div>
        <div className="w-full flex items-start justify-start">
          <span
            className={clsx(
              !isDarkTheme ? 'text-gray-400' : 'text-gray-300',
              'w-2/5'
            )}
          >
            Test Center :{' '}
          </span>
          <span
            className={clsx(
              !isDarkTheme ? 'text-black' : 'text-white',
              'w-3/5 '
            )}
          >
            {cube.name || 'Not provided'}
          </span>
        </div>
        <div className="w-full flex items-start justify-start">
          <span
            className={clsx(
              !isDarkTheme ? 'text-gray-400' : 'text-gray-300',
              ' w-2/5'
            )}
          >
            Address :{' '}
          </span>
          <span
            className={clsx(
              !isDarkTheme ? 'text-black' : 'text-white',
              'w-3/5'
            )}
          >
            {`${cube?.address}, ${cube?.city}, ${cube?.state} ${
              cube?.zipcode ? ` -  ${cube?.zipcode}` : ''
            }`}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CubeMarker;
