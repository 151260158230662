import clsx from 'clsx';
import { Input } from '../../Input/Input';
import { Label } from '../../Label/Label';
import { Email, InfoIcon, Profile } from '../svgs';

export function TextField({
  isEditing = true,
  register,
  valueFn,
  validations,
  label,
  placeHolder,
  identifier,
  containerClassName = 'contents relative',
  inputClass,
  errors,
  disabled = false,
  type = 'text',
  labelClass = 'text-base',
  dialCode,
  step = '',
  mainClass,
}: {
  isEditing?: boolean;
  register: CallableFunction;
  valueFn: CallableFunction;
  validations: { [index: string]: any };
  label: string;
  placeHolder?: string;
  identifier: string;
  containerClassName?: string;
  errors: { [index: string]: any };
  disabled?: boolean;
  type?: string;
  labelClass?: string;
  inputClass?: string;
  dialCode?: string;
  step?: string;
  mainClass?: string;
}) {
  return (
    <div className={containerClassName}>
      <Label className={clsx(labelClass, 'select-none')}>
        {label}
        {(isEditing && validations?.required === 'Is Required') ||
          (validations?.required === 'Required' && isEditing && (
            <span className="text-error">*</span>
          ))}
        {isEditing && identifier === 'clientName' && (
          <div className="group flex relative">
            <InfoIcon className="hover:opacity-70 cursor-pointer" />
            <div className="justify-start items-center group-hover:flex hidden w-[13rem] absolute left-9 -top-5 shadow-3 z-99 bg-black text-white text-sm p-3 font-normal">
              <p className="h-3 w-3 bg-dblack rotate-45 absolute -left-1.5 rounded-sm" />
              If you are freelancer type your project name
            </div>
          </div>
        )}
      </Label>
      <div className={clsx(mainClass, 'flex items-center gap-x-3 !text-base')}>
        {!isEditing && identifier === 'firstName' && (
          <Profile iconColor="#FBFBFB" />
        )}
        {!isEditing && identifier === 'lastName' && (
          <Profile iconColor="#FBFBFB" />
        )}
        {!isEditing && identifier === 'emailId' && (
          <Email iconColor="#FBFBFB" />
        )}
        {!isEditing && identifier === 'alternateEmailId' && (
          <Email iconColor="#FBFBFB" />
        )}
        {isEditing ? (
          <Input
            icon={
              identifier === 'firstName' ? (
                <Profile iconColor="#949494 " />
              ) : identifier === 'lastName' ? (
                <Profile iconColor="#949494 " />
              ) : identifier === 'alternateEmailId' ? (
                <Email iconColor="#949494" />
              ) : identifier === 'emailId' ? (
                <Email iconColor="#949494" />
              ) : null
            }
            iconClass="absolute left-2 top-[1.1rem]"
            containerClassName="h-full select-none relative "
            type={type}
            placeholder={placeHolder}
            className={`${
              !errors[identifier] ? `border-gun-powder ${inputClass}` : ''
            } !text-lg bg-transparent rounded-md h-full max-w-md ${
              identifier === 'alternateMobile' || identifier === 'mobile'
                ? 'pl-16'
                : 'pl-3'
            }  !py-1.5`}
            {...register(identifier, validations)}
            errorClass="absolute -bottom-6 !text-sm"
            errorMsg={
              errors[identifier] && errors[identifier]['message']?.toString()
            }
            isDisabled={disabled}
            step={step}
          />
        ) : !valueFn(identifier) ? (
          <span className="select-none grid content-center text-xl h-full text-rolling-stone">
            --
          </span>
        ) : (
          <span
            className="grid content-center text-xl h-full select-all"
            title={valueFn(identifier)}
          >
            {dialCode}{' '}
            {valueFn(identifier)?.length > 20
              ? valueFn(identifier)?.slice(0, 20) + '...'
              : valueFn(identifier)}
          </span>
        )}
      </div>
    </div>
  );
}
