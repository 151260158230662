import clsx from 'clsx';

interface LabelProps {
  className?: string;
  textColorCss?: string;
  htmlFor?: string;
  children: React.ReactNode;
}

export function Label({
  className = 'block text-base font-medium leading-6',
  textColorCss = 'text-neutral-400',
  htmlFor,
  children,
}: LabelProps) {
  return (
    <label htmlFor={htmlFor} className={clsx(className, textColorCss)}>
      {children}
    </label>
  );
}
