import clsx from 'clsx';
import React from 'react';

type SelectElementProps = React.HTMLProps<HTMLSelectElement> & {
  inputRef?: React.Ref<HTMLSelectElement>;
  errorMsg?: string;
  containerClassName?: string;
  icon?: React.ReactNode;
  iconClass?: string;
  iconPosition?: 'left' | 'right';
  errorClass?: string;
  isDisabled?: boolean;
  setIsVisible?: (arg: boolean) => void;
};

function SelectElement({
  inputRef,
  children,
  errorMsg,
  icon,
  iconClass = 'absolute left-2 top-4',
  iconPosition = 'left',
  containerClassName = '',
  className = 'bg-input text-white',
  errorClass,
  isDisabled = false,
  setIsVisible,
  ...props
}: SelectElementProps) {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // setIsVisible?.(false); // Hide the select element after selecting a value
    // if (props.onChange) {
    //   props.onChange(event);
    // }
  };
  return (
    <div
      className={clsx(
        'w-full flex flex-col select-wrapper',
        containerClassName
      )}
    >
      <select
        className={clsx(
          'px-3 py-4 w-full rounded-md border focus:outline outline-[#0052CC] text-sm leading-4 pr-6 truncate',
          className,
          isDisabled ? '!text-disabled-input' : '',
          errorMsg ? 'border border-error' : '',
          icon ? 'pr-2' : 'px-3',
          {
            'pl-8': icon && iconPosition === 'left',
          },
          {
            'pr-8': icon && iconPosition === 'right',
          }
        )}
        disabled={isDisabled}
        ref={inputRef}
        onChange={handleSelectChange}
        {...props}
      >
        {children}
      </select>
      {icon && <div className={iconClass}>{icon}</div>}
      {errorMsg && (
        <div className={clsx(errorClass, 'text-error text-xs py-1')}>
          {errorMsg}
        </div>
      )}
    </div>
  );
}

export const Select = React.forwardRef<
  HTMLSelectElement,
  Omit<SelectElementProps, 'inputRef'>
>((props, ref) => {
  return <SelectElement {...props} inputRef={ref} />;
});
