import clsx from 'clsx';
import React from 'react';

type InputElementProps = React.HTMLProps<HTMLInputElement> & {
  icon?: React.ReactNode;
  iconClass?: string;
  containerClassName?: string;
  errorMsg?: string;
  hasErrors?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  iconPosition?: 'left' | 'right';
  autoComplete?: string;
  errorClass?: string;
  isDisabled?: boolean;
};

function InputElement({
  type,
  icon,
  inputRef,
  errorMsg,
  hasErrors,
  iconClass = 'absolute left-2 top-4',
  iconPosition = 'left',
  containerClassName = 'w-full flex flex-col',
  className = 'placeholder:text-gray bg-input text-white rounded-md border-transparent ',
  autoComplete,
  errorClass,
  isDisabled = false,
  ...props
}: InputElementProps) {
  return (
    <div className={clsx(containerClassName, 'relative ')}>
      <input
        type={type}
        className={clsx(
          className,
          isDisabled ? '!text-disabled-input' : '',
          'w-full focus-within:outline-0 focus:border-1 focus:border-focus py-4 shadow-sm text-base leading-4',
          icon ? 'pr-2' : 'px-3',
          errorMsg || hasErrors ? 'border-1 border-error' : 'border-1 ',
          {
            'pl-8': icon && iconPosition === 'left',
          },
          {
            'pr-8': icon && iconPosition === 'right',
          }
        )}
        disabled={isDisabled}
        {...props}
        ref={inputRef}
        autoComplete={autoComplete}
      />
      {icon && <div className={iconClass}>{icon}</div>}
      {errorMsg && (
        <div
          className={clsx(
            errorClass,
            'text-error text-sm py-1 absolute top-[92%] whitespace-normal'
          )}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
}

export const Input = React.forwardRef<
  HTMLInputElement,
  Omit<InputElementProps, 'inputRef'>
>((props, ref) => {
  return <InputElement {...props} inputRef={ref} />;
});
