'use client';

import clsx from 'clsx';
import { useRouter } from 'next/navigation';

export default function HeroSection() {
  const router = useRouter();

  return (
    <div className="w-full lg:min-h-screen lg:min-h-svh flex flex-col justify-start items-center lg:mb-10 px-6 lg:px-0">
      <div
        className={clsx(
          'h-full w-full flex  flex-col items-center  transition-all ease-linear relative flex-1 pt-28'
        )}
      >
        <div className="xs:w-full lg:w-1/2  mb-5 text-center">
          <span className="text-blue-700 xs:text-2xl lg:text-[36px] leading-0 font-bold  ">
            Real People,
          </span>
          <span className="text-neutral-700 xs:text-2xl lg:text-[36px] leading-0 font-bold ">
            {' '}
            Authentic Discussions. Transforming{' '}
          </span>
          <span className="text-blue-700 xs:text-2xl lg:text-4xl leading-0 font-bold ">
            Hiring
          </span>
          <span className="text-neutral-700 xs:text-2xl lg:text-4xl leading-0 font-bold ">
            {' '}
            Together
          </span>
        </div>
        <div className="xs:w-full lg:w-[1100px]  text-center text-zinc-600 xs:text-sm lg:text-base font-normal leading-[27px] xs:px-3 lg:px-0">
          Interview Cube is the first-ever ID verification-integrated
          interviewing platform.
          <br />
          <p className="xs:mt-4 lg:mt-0">
            Modern In-Cube Interviews or Remote allow authentic interviews with
            transparent conversations, ensuring the right hire for a company
            powered by AI-Assistant and manual expertise.
          </p>
        </div>
        <div className="xs:mt-6 lg:mt-8 flex justify-center items-center space-x-6 pb-10">
          <button
            className="xs:px-6 lg:px-8 xs:py-2 lg:py-3 relative xs:rounded-lg lg:rounded-full text-blue-700 xs:text-sm lg:text-base font-medium bg-white border-none shadow-2 "
            onClick={() => router.push('/contact-us')}
          >
            Book a Demo
          </button>
          <button
            className="xs:px-6 lg:px-8 xs:py-2 lg:py-3 relative xs:rounded-lg lg:rounded-full bg-blue-700 xs:text-sm lg:text-base font-medium text-white border-none shadow-2"
            onClick={() => router.push('/remote-interview')}
          >
            Join Interview
          </button>
        </div>
        <div className="flex justify-center relative overflow-hidden w-[100vw] flex-1 items-end">
          <video
            preload="none"
            autoPlay
            loop
            muted
            disablePictureInPicture
            className="w-[90vw] lg:w-[80vw] relative z-10 rounded-t-lg mb-[-2px]"
            title="Introduction to Interview Cube"
            aria-label="Introduction video showcasing Interview Cube features"
            aria-describedby="video-description"
          >
            <source
              src="/images/landing-page/interview-cube-intro.webm"
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
          <p id="video-description" className="sr-only">
            This video demonstrates the key features and benefits of the
            Interview Cube platform, including job publishing, scheduling, and
            interviews.
          </p>
          <div className="absolute top-0 left-0 right-0 bottom-[-100%] z-0 flex items-center flex-1">
            <div className="bg-[radial-gradient(42.17%_42.17%_at_50%_44.71%,_#274CE0_0%,_rgba(61,_154,_255,_0.00)_100%)] flex-1 h-[60%] w-[60%]" />
          </div>
        </div>
      </div>
    </div>
  );
}
